import React from "react";

import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import logo from "../assets/images/smalllogo.png";
import taskSquare from "../assets/images/tasksquare.png";
import AssessmentIcon from '@mui/icons-material/Assessment';
import menu from "../assets/images/menu.png";
import MailLockIcon from "@mui/icons-material/MailLock";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import { Link } from "react-router-dom";

export default function LeftNavigationMenu() {
  let userRole;
  try {
    userRole = localStorage.getItem('userRole');
  } catch (err) {
    console.log(err);
  }

  return (
    <>
      <div className="flex-shrink-0 bg-body-tertiary nav-menu-duck">
        <div style={{ marginTop: "25px", marginBottom: "50px" }}>
          <img src={logo} width="40" />
        </div>
        <span className="visually-hidden"></span>
        <ul className="nav nav-pills nav-flush flex-column mb-auto text-center m-hide">
          <li>
            <Link
              to="/worksheet/mine"
              className="nav-link py-3  rounded-0"
              title="My Worksheets"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              style={{ color: 'white' }}
            >
              <AssessmentIcon style={{ width: 38, height: 38 }} />
            </Link>
          </li>
          <li>
            <Link
              to="/report"
              className="nav-link py-3  rounded-0"
              title="Report"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
            >
              <img src={taskSquare} />
            </Link>
          </li>

          <li className="nav-item">
            <Link
              to="/leave"
              className="nav-link py-3  rounded-0"
              title="Leave"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
            >
              <CalendarMonthIcon fontSize="large" sx={{ color: "white" }} />
            </Link>
          </li>
          {userRole === 'ADMIN' && (
            <>
              <li className="nav-item">
                <Link
                  to="/leave/all"
                  className="nav-link py-3  rounded-0"
                  title="All Leaves"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                >
                  <CalendarMonthIcon fontSize="large" sx={{ color: "white" }} />
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/messages"
                  className="nav-link py-3  rounded-0"
                  title="Messages"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                >
                  <MailLockIcon fontSize="large" sx={{ color: "white" }} />
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/project"
                  className="nav-link py-3  rounded-0"
                  title="Project"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                >
                  <AddToPhotosIcon fontSize="large" sx={{ color: "white" }} />
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/employeelist"
                  className="nav-link py-3  rounded-0"
                  title="Employee List"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                >
                  <PeopleAltIcon fontSize="large" sx={{ color: "white" }} />
                </Link>
              </li>
              <li className="dropdown dev-profile">
                <Link
                  to="/report"
                  className="d-flex align-items-center justify-content-center p-3 link-body-emphasis text-decoration-none dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {/* <img
                src={user}
                alt="mdo"
                width="40"
                height="40"
                className="rounded-circle"
              /> */}
                  <img className="tripal-dot-menu" src={menu} />
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </>
  );
}
