import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import api from "../../lib/api";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../layout";
import moment from "moment";
import {Paper} from "@mui/material";
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import clockin from "../../assets/images/clockin-removebg-preview.png";
import start from "../../assets/images/timer.png";
import clockout from "../../assets/images/timer (1).png";
import addtask from "../../assets/images/add.png";
import arrow from "../../assets/images/Vector.png";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
// import CardHeader from "react-bootstrap/esm/CardHeader";
import { Button, Card } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";

const SignupSchema = yup.object().shape({
  projectName: yup.string().required("Project Name Field required"),
  category: yup.string().required("Category is required"),
  //ticketNumber: yup.string().required("Ticket Number Field required"),
  workHours: yup.number().integer().required("Work Hours Field required"),
  
  
  details: yup
    .string()
    .required("Description Field required")
    .min(100, "Minimum 100 Characters"),
});

export default function WorksheetDetail() {
  const navigate = useNavigate();
  const [worksheetDetails, setWorksheetDetails] = useState();
  const [worksheetId, setWorkSheetId] = useState("");
  const [loggedInTime, setLoggedInTime] = useState();
  const [hrNotes, setHrNotes] = useState("");
  const {id} = useParams();
  

  const [details, setDetails] = useState({
    projectName: "",
    ticketNumber: "",
    details: "",
    workHours: "",
    workSheetId: "",
    category:"",
  });
  
  const [worksheetList, setWorksheetList] = useState([]);
  const fetchData = async (e) => {
    try {
      const { data, status } = await api.get(`/worksheet/byid/${id}`);
      if (status == 200) {
        setWorksheetList(data.workSheetRow);
        setWorksheetDetails(data);
        setLoggedInTime(data.worksheetstartdate);
        setWorkSheetId(data.id);
      }
    } catch (error) {
      // alert(error);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  
  return (
    <Layout title={"Historical Worksheet"}>
      <div className="flex-lg-1 main-container  overflow-y-lg-auto">
        <main className="main-data">
        <Paper style={{ padding: 24, minHeight: 600, margin: 100 }} elevation={4}>
          
          <div className="container-fluid" style={{ maxWidth: "100%" }}>
            <div className="row justify-content-center">
              <div className="col-lg-12 ">
                <div className="time-start-wrapper1">
                  <div className="start-btnn">
                    {!worksheetDetails ? (
                      <div className="">
                        <img src={clockin} alt="" />
                        <h5 className="text-center text">Welcome! </h5>
                        <p className="text-center mt-1 text">
                          Clock in and make the most of your shift!
                        </p>
                        <button
                          className="btn btn-primary "
                          
                          style={{
                            height: 75,
                            width: 250,
                            fontSize: 26,
                            fontWeight: 600,
                            marginTop: 25,
                          }}
                        >
                          Clock In{" "}
                        </button>
                        <p
                          className="text-center mt-4"
                          style={{ fontWeight: "600px" }}
                        ></p>
                      </div>
                    ) : (
                      <div>
                        {/* <button disabled className="btn btn-primary">
                          In Progress
                        </button> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-12 mt-2 mb-5 row mx-4 justify-content-center ">
                <div className="col-lg-4 ">
                  {loggedInTime && (
                    <div className="">
                      <Card
                        className="my-2 clockin-card"
                        style={{
                          width: "100%",
                          height: "237px",
                          backgroundColor: "#f1e7f9",
                        }}
                      >
                        <Card.Title className="text-start mt-4 mx-3">
                          <img src={start} alt="" style={{ height: "72px" }} />
                        </Card.Title>
                        <Card.Title
                          className="text-start mx-3 mt-2"
                          style={{ fontSize: "28px" }}
                        >
                          Clock In
                        </Card.Title>

                        <Card.Text className="text-start mx-3">
                          {worksheetDetails?.displayStartDate}
                        </Card.Text>

                        <Card.Text className="text-start mx-3">
                          {/* <span
                                className={
                                  worksheetDetails?.late
                                    ? "red-circle1 text-center d-flex"
                                    : "green-circle1 text-center d-flex "
                                }
                              ></span> */}
                          {worksheetDetails?.late ? (
                            <Badge
                              pill
                              className="late-badge"
                              bg=""
                              text=""
                              style={{
                                backgroundColor: "#FFD0D0",
                                color: "#FF0000",
                              }}
                            >
                              Late
                            </Badge>
                          ) : (
                            <Badge
                              pill
                              className="late-badge"
                              bg=""
                              text=""
                              style={{
                                backgroundColor: "#ECFFDC",
                                color: "green",
                              }}
                            >
                              Success
                            </Badge>
                          )}
                        </Card.Text>
                        {/* <Card.Text
                            className=""
                            style={{ marginTop: "110px" }}
                          >
                            {worksheetDetails?.late ? (
                              <p className="note">
                                NOTE - Please try to check in before your shift
                                time.
                              </p>
                            ) : (
                              <p></p>
                            )}
                          </Card.Text> */}
                      </Card>
                    </div>
                  )}
                </div>
                {worksheetDetails ? (
                  <div className="col-lg-4">
                    {!worksheetDetails?.worksheetenddate && worksheetDetails ? (
                      <div className="">
                        {/* <p className="time text-center mb-4">
                    End at:{" "}
                    <Chip
                      label={worksheetDetails?.displayEndDate}
                      style={{ fontSize: "1.1em" }}
                    />
                  </p> */}
                        <Card
                          className="my-2 clockin-card"
                          style={{
                            width: "100%",
                            height: "237px",
                            backgroundColor: "#eee1e7",
                          }}
                        >
                          <Card.Title className="text-start mt-4 mx-3">
                           
                          </Card.Title>

                          <div className="col-lg-12 row">
                            <div className="col-lg-10">
                              <Card.Title
                                className="text-start mx-3 mt-4"
                                style={{ fontSize: "28px" }}
                              >
                                Clock Out
                              </Card.Title>
                            </div>
                            
                          </div>
                          
                        </Card>
                      </div>
                    ) : (
                      <Card
                        className="my-2 clockin-card"
                        style={{
                          width: "100%",
                          height: "237px",
                          backgroundColor: "#d8dce5",
                        }}
                      >
                        <Card.Title className="text-start mt-4 mx-3">
                          <img
                            src={clockout}
                            alt=""
                            style={{ height: "72px" }}
                          />
                        </Card.Title>

                       
                        <Card.Title
                          className="text-start mx-3 mt-2"
                          style={{ fontSize: "24px" }}
                        >
                          Clock Out
                        </Card.Title>

                        <Card.Text className="text-start mx-3">
                          {worksheetDetails?.displayEndDate}
                        </Card.Text>
                       
                      </Card>
                  
                    )}
                  </div>
                ) : (
                  <div></div>
                )}
               
              </div>

              {/* {worksheetDetails?.worksheetenddate && (
                <div>
                  <div className="">
                    <p className="time text-center mb-4">
                      Worksheet Hours:{" "}
                      <Chip
                        label={worksheetDetails?.workSheetHours}
                        style={{ fontSize: "1.1em" }}
                      />
                    </p>
                  </div>
                  <div className="">
                    <p className="time text-center mb-4">
                      Task Hours?:{" "}
                      <Chip
                        label={worksheetDetails?.workSheetRowsHours}
                        style={{ fontSize: "1.1em" }}
                      />
                    </p>
                  </div>
                  <div className="">
                    <p className="time text-center mb-4">
                      Not Started on Shift Time?:{" "}
                      <Chip
                        label={worksheetDetails?.leftEarly}
                        style={{ fontSize: "1.1em" }}
                      />
                      HalfDay?:{" "}
                      <Chip
                        label={worksheetDetails?.leftEarly}
                        style={{ fontSize: "1.1em" }}
                      />
                    </p>
                  </div>
                  <div className="">
                    <p className="time text-center mb-4">
                      HalfDay?:{" "}
                      <Chip
                        label={worksheetDetails?.leftEarly}
                        style={{ fontSize: "1.1em" }}
                      />
                    </p>
                  </div>
                </div>
              )} */}
              {worksheetDetails ? (
                <>
                <h5 className="text-start mb-4" style={{ fontSize: "24px" }}>
                  Work Breakup
                </h5>
                <div className="row my-2 ">
                <table class="table table-css">
                  <thead>
                    <tr>
                      <th scope="col" className="col1">
                        Project Name
                      </th>
                      <th scope="col" className="col1">
                        Category
                      </th>
                      <th scope="col" className="col2">
                        Ticket Number
                      </th>
                      <th scope="col" className="col3">
                        Hours
                      </th>
                      <th scope="col" className="col4">
                        Description
                      </th>
                    </tr>
                  </thead>
                  {worksheetList?.map((item) => {
                    return (
                      <tbody className="table-hover">
                        <tr>
                          <td>{item.projectName}</td>
                          <td>{item.category}</td>
                          <td>{item.ticketNumber}</td>
                          <td>{item.workHours} Hours</td>
                          <td className="row4 text-start"> {item.details}</td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
                {/* <div className="col-lg-2">
                      <div className="box1">
                        <p className="proj-name text-start px-4 pt-3">
                          {item.projectName}
                        </p>
                        <div className="col-lg-12 row ">
                          <div className="col-lg-6">
                            <p className="ticket-no text-start px-4 mt-4">
                              {" "}
                              {item.ticketNumber}
                            </p>
                          </div>
                          <div className="col-lg-6 text-end hours-box">

                            <span className="hours text-end">
                              {" "}
                              {item.workHours} Hours
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Card className="col-lg-10 my-2">
                      <div className="box1">
                        <p className="details text-start mx-2">
                          {item.details}
                        </p>
                      </div>
                    </Card> */}
              </div>
              </>
              ) : (
                <div></div>
              )}
              

              {/* <Box sx={{ height: 400, width: "100%" }}>
              </Box> */}
              {/* <DataGrid
                rows={worksheetList}
                getRowId={(row) => row.ticketNumber}
                columns={columns}
              /> */}
              {worksheetDetails && !worksheetDetails?.worksheetenddate && (
                <div className="col-lg-12 mt-5">
                  <div className="card">
                    <div className="card-body">
                      <div className="hr-note">
                        <h3>Note to HR</h3>
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="4"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                 
                </div>
              )}
            </div>
          </div>
          {/* {!worksheetDetails?.worksheetenddate && worksheetDetails ? (
            <div>
              <div className="start-btn mt-2">
                <button className="btn btn-primary" onClick={handleEnd}>
                  End Time
                </button>
              </div>
            </div>
          ) : (
            <div></div>
          )} */}
          </Paper>
        </main>
        
        </div>
      
    </Layout>
  );
}
