import React from "react";
import "./index.css";
import logo1 from "../assets/images/twelvespring-removebg-preview.png";
import LeftNavigationMenu from "./leftbar"
import ProfileMenu from "./profilemenu"

export default function Layout({ title, children }) {
  return (
    <body>
      <div className="d-flex flex-lg-row h-lg-full bg-surface-secondary">
       <LeftNavigationMenu/>
        </div>
        <div className="nav-menu-duck1">
          <img src={logo1} className="logo" alt="" />
        </div>
        <nav className="navbar navbar-light position-lg-sticky py-0">
          <div className="container-fluid g-0">
            <div className="top-menu-wrapper">
             <ProfileMenu title={title} />
            </div>
          </div>
        </nav>

        {children}
      
    </body>
  );
}
