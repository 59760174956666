import React from "react";
import Layout from "../layout";
import { Paper, Typography } from "@mui/material";

function Notes() {
  return (
    <Layout title={"My Notes"}>
      <div className="flex-lg-1 main-container  overflow-y-lg-auto">
        <main className="main-data">
          <Paper
            style={{ padding: 24, minHeight: 600, margin: 100 }}
            elevation={4}
          >
            <div>
              <h2>Notes - Coming Soon</h2>
            </div>
            <div>
              <Typography></Typography>
              <Typography></Typography>
            </div>
          </Paper>
        </main>
      </div>
    </Layout>
  );
}

export default Notes;
