import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useController, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Layout from "../../layout";
import { Box, Checkbox, Divider, FormControlLabel, InputLabel, MenuItem, Paper, Select, Typography } from "@mui/material";
import "./leave.css";
import Badge from "react-bootstrap/Badge";
import Dropdown from "react-bootstrap/Dropdown";
import { Button } from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import api from "../../lib/api";
import dayjs from "dayjs";
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import moment from "moment";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import DateField from "../../components/DateField";
import { differenceInDays } from "date-fns";
import { Close } from "@mui/icons-material";

const SignupSchema = yup.object().shape({
  //   leaveType: yup.string().required("Leave Type Field required"),
  startDate: yup.string().required("Date is required"),
  endDate: yup.string().required("Date is required"),
  // leaveDays: yup
  //   .number()
  //   .positive("This field must contain a positive number")
  //   .required("No of days Field required"),
  // remainingLeaves: yup
  //   .number("Invalid number")
  //   .integer("This field should contain an integer")
  //   .required("Remaining leaves Field required"),

  description: yup.string().required("Reason Field required"),
});

function RhfCheckbox({ name, control, label, ...rest }) {
  const { field } = useController({ name, control });
  return (
    <FormControlLabel
      control={(
        <Checkbox {...rest} {...field} checked={field.value} />
      )}
      label={label}
    />
  );
}

function LeaveStat({ label, value, bgColor }) {
  return (
    <div
      className="box-leave"
      style={{ width: 200 }}
    >
      <h5 className="text-center">{label}</h5>
      <Typography textAlign='center'>{value}</Typography>
    </div>
  );
}

function Leave() {
  const [showDialog, setShowDialog] = useState({
    open: false,
    title: "Add Leaves",
    data: {},
  });
  const [leaveData, setLeaveData] = useState([]);
  const [leaveStats, setLeaveStats] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { id } = useParams();
  const [totalLeave, setTotalLeave] = useState({
    "Casual / Paid Leave": 0,
    "Medical/Sick Leave": 0,
    "Unpaid Leave": 0,
    "Festival Leave": 0,
  });
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignupSchema),
    defaultValues: {
      leaveType: "",
      startDate: new Date(),
      endDate: new Date(),
      leaveDays: "",
      remainingLeaves: "",
      // status: "",
    },
  });

  const handleDialog = () => {
    setShowDialog(() => ({ open: true }));
  };

  const closeDialog = () => {
    setShowDialog((prev) => ({ open: false }));
    reset({
      leaveType: "",
      startDate: new Date(),
      endDate: new Date(),
      leaveDays: "",
      remainingLeaves: "",
      // status: "",
    });
  };

  useEffect(() => {
    let ws = watch("startDate");
    let we = watch("endDate");
    if (typeof ws === "string" && typeof we === "string") {
      console.log(ws, we, "watchh");
      setValue("leaveDays", String(moment(we).diff(moment(ws), "days") + 1));
    }
  }, [watch(["startDate", "endDate"])]);

  const leaveMangement = async () => {
    try {
      const { data, status } = await api.get(`/leaves`);
      if (status == 200) {
        setLeaveStats({
          limit: data.leavesLimit,
          taken: data.leavesTaken,
        });
        setLeaveData(data.leaves);
      }
    } catch (error) {
      // alert(error);
    }
  };

  function fetchEmp() {
    const { data } = api.get('');
  }

  useEffect(() => {
    leaveMangement();
  }, []);
  const handleAddLeaves = async (e) => {
    //e.preventDefault();
    setIsSubmitted(true);

    try {
      const { data } = await api.post(`/leaves`, {
        ...e,
        startDate: moment(e.startDate).format("YYYY-MM-DD"),
        endDate: moment(e.endDate).format("YYYY-MM-DD"),
      });
      leaveMangement();
      closeDialog();
      reset();
    } catch (error) {
      console.log(error);
    }
    // }
  };
  const handleWthdLeave = async (e) => {
    //e.preventDefault();
    setIsSubmitted(true);

    try {
			await api.put(`/leaves/${e.id}/status?v=Withdrawn`)
      leaveMangement();
      closeDialog();
      reset();
    } catch (error) {
      console.log(error);
    }
    // }
  };

  const removeItem = (index) => {
    setLeaveData(leaveData.filter((o, i) => index !== i));
  };
  const handleCancelLeaves = async (e) => {
    //e.preventDefault();
    setIsSubmitted(true);
    // setValue("status ", "Cancelled");

    try {
      const { data } = await api.put(`/leaves`, {
        ...e,
        status: "Cancelled",
      });
      console.log(data.status);
      leaveMangement(data);
      closeDialog();
      reset();
    } catch (error) {
      console.log(error);
    }
    // }
  };

  return (
    <>
      <Layout title={"Leave"}>
        <div className="flex-lg-1 main-container  overflow-y-lg-auto">
          <main className="main-data">
            <Paper
              style={{ padding: 24, minHeight: 600, margin: 100 }}
              elevation={4}
            >
              <div className=" float-end me-5 mb-3">
                <Button
                  className="text-white"
                  variant="dark"
                  onClick={() => {
                    setShowDialog((prev) => ({
                      ...prev,
                      open: true,
                      title: "Add Leave",
                      handleSubmit: handleAddLeaves,
                    }));
                    reset();
                  }}
                >
                  {" "}
                  <AddIcon /> Add Leave
                </Button>
              </div>
              <div>
                {leaveStats && (
                  <Box
                    sx={{ display: 'flex', gap: 2 }}
                  >
                    <LeaveStat label='Leaves Limit' value={leaveStats.limit} />
                    <LeaveStat label='Leaves Taken' value={leaveStats.taken} />
                    <LeaveStat label='Leaves Balance' value={leaveStats.limit - leaveStats.taken} />
                  </Box>
                )}

                <div className="container-fluid">
                  <table
                    id="example"
                    className="table table-striped table-bordered"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th>Leave Type</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>No of Days</th>
                        <th>Reason</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    {console.log(moment())}
                    {leaveData?.map((item, id) => {
                      return (
                        <tbody key={id}>
                          <tr>
                            <td>{item.leaveType}</td>
                            <td>
                              {moment(item.startDate).format("DD-MM-YYYY")}
                            </td>
                            <td>
                              {" "}
                              {moment(item.endDate).format("DD-MM-YYYY")}
                            </td>
                            <td>{item.leaveDays}</td>
                            {/* <td>{item.remainingLeaves}</td> */}
                            <td>{item.description}</td>
                            <td>
                              <h5>
                                {" "}
                                <Badge
                                  className="badge1"
                                  bg={
                                    item.status === "Pending"
                                      ? "info"
                                      : item.status === "Approved"
                                        ? "success"
                                        : item.status === "Not Approved"
                                          ? "danger"
                                          : item.status === "Cancelled"
                                            ? "secondary"
                                            : "warning"
                                  }
                                >
                                  {item.status}
                                </Badge>
                              </h5>
                            </td>

                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="white"
                                  id="dropdown-basic"
                                >
                                  <i className="fa-solid fa-ellipsis-vertical"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => {
                                    handleWthdLeave(item);
                                  }}>
                                    {" "}
                                    <CloseIcon />{" "}
                                    Withdraw Leave
                                  </Dropdown.Item>

                                  {moment(item.startDate).diff(moment()) > 0 ? (
                                    <>
                                      <Divider
                                        sx={{ borderColor: "#315631" }}
                                      />
                                      <Dropdown.Item
                                        onClick={() => {
                                          handleCancelLeaves(item);
                                        }}
                                      >
                                        <DeleteIcon /> Cancel Leave
                                      </Dropdown.Item>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </div>
            </Paper>
            <Dialog
              open={showDialog.open}
              onClose={closeDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                style: {
                  backgroundColor: "white",
                  boxShadow: "none",
                  height: "800px",
                  width: "500px",
                },
              }}
            >
              <DialogTitle
                className="text-center text-black"
                id="alert-dialog-title"
                style={{ fontSize: "28px", fontWeight: "600" }}
              >
                {showDialog.title}
                <div className="float-end">
                  <CloseIcon
                    onClick={closeDialog}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </DialogTitle>
              <form onSubmit={handleSubmit(showDialog.handleSubmit)}>
                <DialogContent>
                  <div className="input-group mb-2">
                    <InputLabel id="demo-simple-select-label">
                      Leave Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      //   label="Leave Type"
                      //   value={leaveTypes}
                      value={watch("leaveType")}
                      onChange={(e, nv) => {
                        console.log(e.target.value);
                        setValue("leaveType", e.target.value);
                      }}
                      //   {...register("leaveType")}
                      sx={{
                        width: 430,
                      }}
                    >
                      <MenuItem value="Medical/Sick Leave">
                        Medical/Sick Leave
                      </MenuItem>
                      <MenuItem value="Casual / Paid Leave">
                        Casual/Paid Leave
                      </MenuItem>
                      <MenuItem value="Unpaid Leave">Unpaid Leave</MenuItem>
                      <MenuItem value="Festival Leave">Festival Leave</MenuItem>
                    </Select>

                    {/* <select
                      className="form-select"
                      id="inputGroupSelect02"
                      {...register("leaveType")}
                    >
                      <option>Select Leave Type</option>
                      <option value="Medical Leave">Medical/Sick Leave</option>
                      <option value="Casual / Paid Leave">
                        Casual / Paid Leave
                      </option>
                      <option value="Unpaid Leave">Unpaid Leave</option>
                    </select> */}
                  </div>
                  {errors.leaveType && (
                    <span className="text-danger mx-2">
                      {errors.leaveType.message}
                    </span>
                  )}

                  <div className="form-field ticket-field mb-2 row">
                    <div className="col-lg-6">
                      <label>Start Date</label>
                      <DateField
                        control={control}
                        // label="Start Date"
                        name="startDate"
                      // disablePast
                      />

                      {/* <input
                      style={{ borderColor: errors.startDate && "red" }}
                      type="text"
                      {...register("startDate")}
                      className="form-control"
                    /> */}
                      {errors.startDate && (
                        <span className="text-danger mx-2">
                          {errors.startDate.message}
                        </span>
                      )}
                    </div>
                    <div className="form-field ticket-field mb-2 col-lg-6">
                      <label>End Date</label>
                      <DateField
                        control={control}
                        // label="End Date"
                        name="endDate"
                        // disablePast
                        minDate={dayjs(watch("startDate"))}
                      />
                      {/* <input
                      style={{ borderColor: errors.endDate && "red" }}
                      type="text"
                      {...register("endDate")}
                      className="form-control"
                    /> */}
                      {errors.endDate && (
                        <span className="text-danger mx-2">
                          {errors.endDate.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <RhfCheckbox name='isHalfDay' control={control} label='Half-Day?' />

                  <div className="form-field ticket-field mb-2">
                    {/* <label>Remaining Leaves</label>

                    <TextField
                      {...register("remainingLeaves")}
                      sx={{
                        width: 430,
                        borderColor: errors.remainingLeaves && "red",
                      }}
                    />

                    {errors.remainingLeaves && (
                      <span className="text-danger mx-2">
                        {errors.remainingLeaves.message}
                      </span>
                    )} */}
                  </div>

                  <div className="form-field mt-3">
                    {/* <label>Leave Reason</label> */}

                    <textarea
                      style={{ borderColor: errors.description && "red" }}
                      {...register("description")}
                      type="text"
                      className="form-control"
                      placeholder="Leave Reason"
                      // onChange={(e) => {
                      //   handleChange(e.target.value, "description");
                      // }}
                      rows={3}
                      multiline={true}
                    />
                    {errors.description && (
                      <p className="text-danger mx-2">
                        {errors.description.message}
                      </p>
                    )}
                  </div>
                </DialogContent>
                <DialogActions>
                  {/* <button onClick={closeDialog} className="btn btn-secondary">
                Cancel
              </button> */}
                  {/* <div className="start-btn1 mx-2">
                    <button
                      autoFocus
                      className="btn btn-secondary"
                      onClick={closeDialog}
                    >
                      Cancel
                    </button>
                  </div> */}

                  {/* <button
                onClick={handleAddWorksheet}
                autoFocus
                className="btn btn-primary"
              >
                Add
              </button> */}
                  <div className="start-btn2 mx-2" style={{}}>
                    <button
                      type="submit"
                      autoFocus
                      className="btn btn-warning text-white"
                    // onClick={handleAddWorksheet}
                    >
                      Submit
                    </button>
                  </div>
                </DialogActions>
              </form>
            </Dialog>
          </main>
        </div>
      </Layout>
    </>
  );
}

export default Leave;
