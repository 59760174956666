import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./pages/login";
import Worksheet from "./pages/worksheet";
import WorksheetReport from "./pages/worksheet/report";
import Logout from "./pages/login/logout";
import SignUp from "./pages/signup/signup";
import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { ToastContext } from "./lib/toastctx";
import WorkSheetReportDetail from "./pages/worksheet/detail";
import Lead from "./pages/messages/list";
import TSMessage from "./pages/messages/list";
import EmployeeProfile from "./pages/employee/profile";
import WorksheetDetail from "./pages/worksheet/detail";
import Leave from "./pages/leave-management/leave";
import Project from "./project/project";
import EmployeeList from "./pages/employee/employeeList";
import EmployeeDetails from "./pages/employee/employeeDetail";
import HandleDrawer from "./pages/messages/drawer";
import Notes from "./project/notes";
import MyWorksheet from "./pages/worksheet/mine";
import AllLeaves from "./pages/leave-management/AllLeaves";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App() {
  const [toast, setToast] = React.useState({
    open: false,
    type: "success",
    msg: "",
    vertical: "top",
    horizontal: "center",
  });

  const openToast = (type, msg) => {
    setToast({ open: true, type, msg });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setToast({ ...toast, open: false });
  };

  return (
    <div className="App">
      <ToastContext.Provider value={{ openToast }}>
        <Snackbar
          open={toast.open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        >
          <Alert
            onClose={handleClose}
            severity={toast.type}
            sx={{ width: "100%" }}
          >
            {toast.msg}
          </Alert>
        </Snackbar>
        <Router>
          <Routes>
            <Route
              exact
              path="/"
              element={<Navigate to="/login" replace={true} />}
            />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/signup" element={<SignUp />} />
            <Route exact path="/worksheet" element={<Worksheet />} />
            <Route exact path="/worksheet/mine" element={<MyWorksheet />} />
            <Route exact path="/detail/:id" element={<WorksheetDetail />} />
            <Route exact path="/report" element={<WorksheetReport />} />
            {/* <Route exact path="/demo" element={<HandleDrawer />} /> */}
            <Route exact path="/messages" element={<TSMessage />} />
            <Route exact path="/messages/inbox" element={<TSMessage />} />
            <Route exact path="/profile" element={<EmployeeProfile />} />
            <Route exact path="/logout" element={<Logout />} />
            <Route exact path="/leave" element={<Leave />} />
            <Route exact path="/leave/all" element={<AllLeaves />} />
            <Route exact path="/leave/:id" element={<Leave />} />
            <Route exact path="/project" element={<Project />} />
            <Route exact path="/notes" element={<Notes />} />
            <Route exact path="/employeelist" element={<EmployeeList />} />
            <Route
              exact
              path="/employeelist/employeedetail"
              element={<EmployeeDetails />}
            />
            <Route
              exact
              path="/employeelist/employeedetail/:id"
              element={<EmployeeDetails />}
            />
            {/* <Route exact path="/form" element={<FormComp />} /> */}
          </Routes>
        </Router>
      </ToastContext.Provider>
    </div>
  );
}

export default App;
