import Table from "react-bootstrap/Table";

const thStyle = {
  fontSize: '1rem'
};

export default function WorksheetTable({ rows }) {
  return (
    <Table>
      <thead>
        <tr>
          <th style={thStyle}>Project Name</th>
          <th style={thStyle}>Ticket No.</th>
          <th style={thStyle}>Hours</th>
          <th style={thStyle}>Notes</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row, i) => (
          <tr key={row.ticketNumber + i}>
            <td>{row.projectName}</td>
            <td>{row.ticketNumber}</td>
            <td>{row.workHours}</td>
            <td>{row.details}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}