import React, { useContext, useState } from "react";
import "./index.css";
import logo from "../../assets/images/twelvespring-removebg-preview.png";
import api from "../../lib/api";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../lib/toastctx";

export default function Login() {
  const navigate = useNavigate();

  const { openToast } = useToast();
  const [details, setDetails] = useState({
    authName: "",
    authPassword: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState({
    authName: "",
    authPassword: "",
  });
  const handleValidation = () => {
    console.log(error, details);
    let isError = false,
      userErr = "",
      passwordErr = "";
    if (details.authName == "") {
      userErr = "Username cannot be blank";
      isError = true;
    }
    if (details.authPassword == "") {
      passwordErr = "Password cannot be blank";
      isError = true;
    }
    if (isError == true) {
      setError({
        authName: userErr || "",
        authPassword: passwordErr || "",
      });
      return false;
    } else {
      setError({
        authName: "",
        authPassword: "",
      });
      return true;
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    const isValidated = handleValidation();
    if (isValidated) {
      try {
        const { data } = await api.post("/auth", details);
        setDetails({
          authUser: "",
          authPassword: "",
        });
        localStorage.setItem("authToken", data.authToken);
        localStorage.setItem('userRole', data.data.role);
        // //localStorage.setItem("userData", JSON.stringify(data.data));
        localStorage.setItem("userName", data.data.firstName + " " + data.data.lastName)
        navigate("/worksheet");
      } catch (error) {
        console.log(error);
        openToast('error', error.response?.data?.message || 'Unable to login')
      }
      // .then(({ data, status }) => {
      //   openToast("success", "Your signup was successful");
      //   localStorage.setItem("authToken", data.authToken);
      //   localStorage.setItem("userData", JSON.stringify(data.data));
      //   navigate("/worksheet");
      // })
      // .catch(({ response: { data } }) => {
      //   if (data) openToast("error", data.message || "Error");
      // });
    }
  };

  const handleChange = (value, key) => {
    setDetails({ ...details, [key]: value });
    if (isSubmitted) {
      handleValidation();
    }
  };
  return (
    <body>
      <main className="min-vh-100 login">
        <div className="form-signin ">
          <form>


            <h1 className="login-title">Login Account</h1>

            <div className="mb-3 duck-input email-icon">
              <label for="exampleFormControlInput1" className="form-label">

              </label>
              <input
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Username"
                onChange={(e) => {
                  handleChange(e.target.value, "authName");
                }}
              />
              {error.authName && (
                <label className="error">{error.authName}</label>
              )}
            </div>

            <div className="mb-3 duck-input pwd-icon">
              <label for="exampleFormControlInput1" className="form-label">

              </label>
              <input
                type="password"
                className="form-control"
                id="Password"
                placeholder="Password"
                onChange={(e) => {
                  handleChange(e.target.value, "authPassword");
                }}
              />
              {error.authPassword && (
                <label className="error">{error.authPassword}</label>
              )}
            </div>

            <div className="btn-wrapper">
              {/* <button
                className=" btn btn-lg  duck-btn"
                // type="submit"
                onClick={handleLogin}
              >
                Login
              </button> */}
              <button
                className="btn btn-primary"
                onClick={handleLogin}
                style={{
                  height: 75,
                  width: 250,
                  fontSize: 26,
                  fontWeight: 600,
                  marginTop: 25,
                  backgroundColor: "black",
                }}
              >
                Login
              </button>
              <div className="nav-menu-duck1">
                <img src={logo} className="logo" alt="" />
              </div>
            </div>

            {/* <div className="btn-wrapper">
              <a href="/signup" className="forgot-pwd">
                Not an user? Signup
              </a>
            </div> */}
          </form>
        </div>
        {/* <div>
          <img src={logo} className="" alt="TwelveSprings" />
        </div> */}
      </main>
    </body>
  );
}
