import React from "react";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";

function InboxMessage(props) {
  const { message } = props;
  return (
    <>
      <div className="inbox-messages text-start">
        <h5 className="inbox-messages-txt" style={{ fontWeight: "400" }}>
          {message?.subject}
        </h5>
        <span className=" badge bg-primary">Personal</span>
        <span className=" badge bg-primary mx-2">Work</span>
        <span className=" badge bg-primary">Payment</span>
      </div>

      <div className="inbox-messages-box ms-3 text-start pt-4 ps-3">
        <AccountCircleRoundedIcon fontSize="large" />{" "}
        <span> {message?.name}</span>
        <div>
          <h6 className="mb-3 mt-5" style={{ fontWeight: "400" }}>
            {message?.subject}
          </h6>
          <p>{message?.message}</p>
        </div>
      </div>
    </>
  );
}

export default InboxMessage;
