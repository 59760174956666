import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Paper } from "@mui/material";
import Layout from "../../layout";
import InboxMessage from "./inboxMessage";
import { useState } from "react";
import DehazeIcon from "@mui/icons-material/Dehaze";
import AddIcon from "@mui/icons-material/Add";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import SendIcon from "@mui/icons-material/Send";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function HandleDrawer() {
  const [selectData, setSelectData] = useState();

  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [inboxData, setInboxData] = useState([
    {
      name: "Mohammmed",
      subject: " review and sign the attached agreement",
      message:
        "Hi mohammed, Ullamco deserunt commodo esse deserunt deserunt quis eiusmod. Laborum sint excepteur non sit eiusmod sunt voluptate ipsum nisi ullamco magna. Lorem consectetur est dolor minim exercitation deserunt quis duis fugiat ipsum incididunt non. Anim aute ipsum cupidatat nisi occaecat quis sit nisi labore labore dolore do.",
    },
    {
      name: "Myra ",
      subject: "Urgent Required",
      message:
        "Hi , Ullamco deserunt commodo esse deserunt deserunt quis eiusmod. Laborum sint excepteur non sit eiusmod sunt voluptate ipsum nisi ullamco magna. Lorem consectetur est dolor minim exercitation deserunt quis duis fugiat ipsum incididunt non. Anim aute ipsum cupidatat nisi occaecat quis sit nisi labore labore dolore do.",
    },
    {
      name: " Dudley",
      subject: "Request",
      message:
        "Ullamco deserunt commodo esse deserunt deserunt quis eiusmod. Laborum sint excepteur non sit eiusmod sunt voluptate ipsum nisi ullamco magna. Lorem consectetur est dolor minim exercitation deserunt quis duis fugiat ipsum incididunt non. Anim aute ipsum cupidatat nisi occaecat quis sit nisi labore labore dolore do.",
    },
  ]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Layout title={"HR Messages"}>
      <div className="flex-lg-1 main-container  overflow-y-lg-auto">
        <main className="main-data ">
          <Paper
            style={{ padding: 24, minHeight: 600, margin: 100 }}
            elevation={4}
          >
            <Box sx={{ display: "flex" }}>
              <CssBaseline />

              <Drawer
                sx={{
                  width: drawerWidth,
                  flexShrink: 0,
                  "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box",
                  },
                }}
                variant="persistent"
                anchor="left"
                open={open}
              >
                <DrawerHeader>
                  <IconButton onClick={handleDrawerClose}>
                    {theme.direction === "ltr" ? (
                      <ChevronLeftIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                  <div className="row col-lg-12">
                    <div className="col-lg-3  mail">
                      <div>
                        {/* <h2>Connect with HR - Coming Soon</h2> */}
                        <h2 className="text-start ms-3">MailBox</h2>
                      </div>
                      <div className="text-start mt-4">
                        <button
                          className="button-34 "
                          // onClick={() => {
                          //   setShowDialog((prev) => ({
                          //     ...prev,
                          //     open: true,
                          //     title: "New Message",
                          //     // handleSubmit: handleAddLeaves,
                          //   }));
                          //   reset();
                          // }}
                        >
                          {" "}
                          <AddIcon /> Compose
                        </button>
                      </div>
                      <div className="text-start my-5 ms-3">
                        <h6 style={{ color: "#5e5df0" }}>MAILBOXES</h6>
                        <ul className="nav nav-pills nav-flush flex-column mb-auto text-start m-hide">
                          <li className="mail-txt ps-3">
                            <a
                              href="/"
                              className="nav-link rounded-0 "
                              title="Inbox"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                            ></a>
                            <AllInboxIcon className="pb-1" />{" "}
                            <span className="ms-2 pb-5">Inbox</span>
                          </li>
                          <li className="mail-txt mt-3 ps-3">
                            <a
                              href="/"
                              className="nav-link  rounded-0"
                              title="Sent"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                            ></a>
                            <SendIcon className="pb-1" />{" "}
                            <span className="ms-2 pb-5">Sent</span>
                          </li>
                          <li className="mail-txt mt-3 ps-3">
                            <a
                              href="/"
                              className="nav-link  rounded-0"
                              title="Draft"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                            ></a>
                            <InsertDriveFileIcon className="pb-1" />{" "}
                            <span className="ms-2 pb-5">Draft</span>
                          </li>
                          <li className="mail-txt mt-3 ps-3">
                            <a
                              href="/"
                              className="nav-link  rounded-0"
                              title="Spam"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                            ></a>
                            <GppMaybeIcon className="pb-1" />{" "}
                            <span className="ms-2 pb-5">Spam</span>
                          </li>
                          <li className="mail-txt mt-3 ps-3">
                            <a
                              href="/"
                              className="nav-link  rounded-0"
                              title="Trash"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                            ></a>
                            <DeleteOutlineIcon className="pb-1" />{" "}
                            <span className="ms-2 pb-5">Trash</span>
                          </li>
                        </ul>
                      </div>
                      <div className="text-start my-5 ms-3">
                        <h6 style={{ color: "#5e5df0" }}>MAILBOXES</h6>
                        <ul className="nav nav-pills nav-flush flex-column mb-auto text-start m-hide">
                          <li className="mail-txt ps-3">
                            <a
                              href="/"
                              className="nav-link rounded-0 "
                              title="Inbox"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                            ></a>
                            <AllInboxIcon className="pb-1" />{" "}
                            <span
                              className="ms-2 pb-5"
                              style={{ fontSize: "18px" }}
                            >
                              Inbox
                            </span>
                          </li>
                          <li className="mail-txt mt-3 ps-3">
                            <a
                              href="/"
                              className="nav-link  rounded-0"
                              title="Sent"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                            ></a>
                            <SendIcon className="pb-1" />{" "}
                            <span
                              className="ms-2 pb-5"
                              style={{ fontSize: "18px" }}
                            >
                              Sent
                            </span>
                          </li>
                          <li className="mail-txt mt-3 ps-3">
                            <a
                              href="/"
                              className="nav-link  rounded-0"
                              title="Draft"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                            ></a>
                            <InsertDriveFileIcon className="pb-1" />{" "}
                            <span
                              className="ms-2 pb-5"
                              style={{ fontSize: "18px" }}
                            >
                              Draft
                            </span>
                          </li>
                          <li className="mail-txt mt-3 ps-3">
                            <a
                              href="/"
                              className="nav-link  rounded-0"
                              title="Spam"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                            ></a>
                            <GppMaybeIcon className="pb-1" />{" "}
                            <span
                              className="ms-2 pb-5"
                              style={{ fontSize: "18px" }}
                            >
                              Spam
                            </span>
                          </li>
                          <li className="mail-txt mt-3 ps-3">
                            <a
                              href="/"
                              className="nav-link  rounded-0"
                              title="Trash"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                            ></a>
                            <DeleteOutlineIcon className="pb-1" />{" "}
                            <span
                              className="ms-2 pb-5"
                              style={{ fontSize: "18px" }}
                            >
                              Trash
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div
                      className="col-lg-4 "
                      style={{ borderRight: "1px solid rgba(0, 0, 0, 0.167)" }}
                    >
                      <div
                        className="text-start ms-3 mb-3 "
                        id="drawer-toggle-label"
                      >
                        <DehazeIcon
                          onClick={() => {
                            alert("hh");
                            // setShow(true);
                          }}
                          sx={{ cursor: "pointer" }}
                        />
                        <span
                          className="ms-5"
                          style={{ color: "black", fontWeight: "500" }}
                        >
                          INBOX
                        </span>
                      </div>
                      {inboxData.map((item, index) => (
                        <>
                          <div
                            key={index}
                            className="inbox text-start "
                            onClick={() => {
                              setSelectData(item);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <h6 className="mt-3"> {item.name}</h6>
                            <p>{item.subject}</p>
                            <p className="inbox-msg">{item.message} </p>
                          </div>
                        </>
                      ))}
                      {/* <Inbox inboxData={inboxData} /> */}
                    </div>
                    <div className="col-lg-5 big-screen">
                      <InboxMessage message={selectData} />
                    </div>
                  </div>
                </List>
                <AppBar open={open}>
                  <Toolbar>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleDrawerOpen}
                      edge="start"
                      sx={{ mr: 2, ...(open && { display: "none" }) }}
                    >
                      <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                      Persistent drawer
                    </Typography>
                  </Toolbar>
                </AppBar>
              </Drawer>
              <Main open={open}>
                <DrawerHeader />
                <Typography paragraph>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Rhoncus dolor purus non enim praesent elementum facilisis leo
                  vel. Risus at ultrices mi tempus imperdiet. Semper risus in
                  hendrerit gravida rutrum quisque non tellus. Convallis
                  convallis tellus id interdum velit laoreet id donec ultrices.
                  Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl
                  suscipit adipiscing bibendum est ultricies integer quis.
                  Cursus euismod quis viverra nibh cras. Metus vulputate eu
                  scelerisque felis imperdiet proin fermentum leo. Mauris
                  commodo quis imperdiet massa tincidunt. Cras tincidunt
                  lobortis feugiat vivamus at augue. At augue eget arcu dictum
                  varius duis at consectetur lorem. Velit sed ullamcorper morbi
                  tincidunt. Lorem donec massa sapien faucibus et molestie ac.
                </Typography>
                <Typography paragraph>
                  Consequat mauris nunc congue nisi vitae suscipit. Fringilla
                  est ullamcorper eget nulla facilisi etiam dignissim diam.
                  Pulvinar elementum integer enim neque volutpat ac tincidunt.
                  Ornare suspendisse sed nisi lacus sed viverra tellus. Purus
                  sit amet volutpat consequat mauris. Elementum eu facilisis sed
                  odio morbi. Euismod lacinia at quis risus sed vulputate odio.
                  Morbi tincidunt ornare massa eget egestas purus viverra
                  accumsan in. In hendrerit gravida rutrum quisque non tellus
                  orci ac. Pellentesque nec nam aliquam sem et tortor. Habitant
                  morbi tristique senectus et. Adipiscing elit duis tristique
                  sollicitudin nibh sit. Ornare aenean euismod elementum nisi
                  quis eleifend. Commodo viverra maecenas accumsan lacus vel
                  facilisis. Nulla posuere sollicitudin aliquam ultrices
                  sagittis orci a.
                </Typography>
              </Main>
            </Box>
          </Paper>
        </main>
      </div>
    </Layout>
  );
}
