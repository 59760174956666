import React, { useEffect, useState } from "react";
import api from "../../lib/api";
import { useNavigate } from "react-router-dom";
import Layout from "../../layout";
import Form from "react-bootstrap/Form";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import "./form.css";
import { Paper } from "@mui/material";

// import api from "../../lib/api";

const year = []

const month = [
  { lable: "Jan", value: "01"},
  { lable: "Feb", value: "02" },
  { lable: "Mar", value: "03" },
  { lable: "Apr", value: "04" },
  { lable: "May", value: "05" },
  { lable: "Jun", value: "06" },
  { lable: "Jul", value: "07" },
  { lable: "Aug", value: "08" },
  { lable: "Sep", value: "09" },
  { lable: "Oct", value: "10" },
  { lable: "Nov", value: "11" },
  { lable: "Dec", value: "12" },
];

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();
const currMonth = month.find((item) => {
  return item.value - 1 === currentMonth;
});
export default function WorksheetReport() {
  const [worksheetDetails, setWorksheetDetails] = useState();

  const [Year, setYear] = useState([]);
  const [ffvalue, setFfvalue] = useState({
    year: currentYear,
    month: currMonth.value,
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [worksheetList, setWorksheetList] = useState([]);

  const [data, setData] = useState([]);
  const fetchData = async (e) => {
    let currentDate = new Date().toISOString();
    let month = currentMonth.toString();
    let date = currentDate.substring(0, 10);
    let time = currentDate.substring(11, 19);
    try {
      const { month, data, status } = await api.get(`/worksheet/init/${date}`);
      if (status == 200) {
        setWorksheetList(data.workSheetRow);
        setWorksheetDetails(data);
        // setLoggedInTime(data.worksheetstartdate);
        // setWorkSheetId(data.id);
      }
    } catch (error) {
      // alert(error);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
    setYear(year);
  }, []);

  // const getWorksheet = async (day) => {
  //   const { data, status } = await api.get(
  //     `/worksheet/init/${year}-${month}-${day}`
  //   );
  // };
  const SubmitForm = async () => {
    const { data, status } = await api.get(
      `/worksheet/report/${ffvalue.year}-${ffvalue.month}`
    );
    setData(data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFfvalue({
      ...ffvalue,
      [name]: value,
    });
  };

  const navigate = useNavigate();
  function handleView(id) {
    //const day = `${dayNumber}`.padStart(2, "0"); // pad day num with leading 0 if single digit, eg: 3 -> 03
    navigate("/detail/"+id);
  }

  return (
    <Layout title={"Report"}>
      <div className="flex-lg-1 main-container  overflow-y-lg-auto">
        <main className="main-data">
        <Paper style={{ padding: 24, minHeight: 600, margin: 100 }} elevation={4}>
          <Form className="product">
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Select
                    onChange={handleChange}
                    name="year"
                    value={ffvalue.year}
                  >
                    <option>{currentYear}</option>
                    {/* {Year
                      ? Year.map((item) => {
                          console.log(item);
                          return (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          );
                        })
                      : ""} */}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Select
                    onChange={handleChange}
                    name="month"
                    value={ffvalue.month}
                  >
                    <option value={currMonth.value}>{currMonth.lable}</option>
                    {month.map((item) => {
                      return (
                        <option key={item.value} value={item.value}>
                          {item.lable}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Button
                    className="me5"
                    variant="primary"
                    size="md"
                    onClick={SubmitForm}
                  >
                    Search
                  </Button>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Link to="/worksheet">
                    <button
                      className="btn btn-primary"
                      style={{
                        height: 60,
                        width: 250,
                        fontSize: 26,
                        fontWeight: 600,
                        backgroundColor: "black",
                        marginBottom: 20,
                        borderRadius: 20,
                      }}
                    >
                      Today Worksheet
                    </button>
                  </Link>
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Date </th>
                <th>Day Display</th>
                <th>Attendance</th>
                <th>Work Action</th>
                <th>Hours</th>
              </tr>
            </thead>
            <tbody>
              {data
                ? data.map((item) => {
                    return (
                      <>
                        {(!item.weekend || item.attendance) && (
                          <tr>
                            <td>{item.dayNumber}</td>
                            <td>{item.dayDisplay}</td>
                            <td>
                              {" "}
                              <div
                                className={
                                  item.attendance
                                    ? "green-circle text-center"
                                    : "red-circle text-center"
                                }
                              ></div>
                              {item.attendance}
                            </td>
                            <td>
                              {item.attendance && (
                                <Button
                                  onClick={() => handleView(item.id)}
                                >
                                  {" "}
                                  View
                                </Button>
                              )}
                            </td>
                            <td></td>
                          </tr>
                        )}

                        {item.weekend && !item.attendance && (
                          <tr>
                            <td
                              colspan="5"
                              style={{ backgroundColor: "#72376b3d" }}
                            >
                              Weekend
                            </td>
                          </tr>
                        )}
                      </>
                    );
                  })
                : ""}
            </tbody>
          </Table>
          </Paper>
        </main>
      </div>
    </Layout>
  );
}
