import * as React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export default function Collapsible({ header, children, disabled }) {
  const [open, setOpen] = React.useState(false);

  function toggleOpen() {
    setOpen(!open);
  }

  function handleHeaderClick() {
    if (disabled) return;
    toggleOpen();
  }

  return (
    <div style={{
      display: 'flex', flexDirection: 'column',
    }}
    >
      {/* header */}
      <div style={{
        display: 'flex', alignItems: 'center', cursor: disabled || 'pointer',
        opacity: disabled && 0.5,
        padding: '12px 0'
      }}
        onClick={handleHeaderClick}>
        <div style={{ marginRight: 8 }}>
          {open ? <ExpandMoreIcon /> : <ChevronRightIcon />}
        </div>
        {header}
      </div>
      <hr style={{ margin: 0 }} />
      {open && (
        <div style={{ marginTop: 8 }}>
          {children}
          <hr style={{ margin: 0, marginTop: 8 }} />
        </div>
      )}
    </div>
  );
}
