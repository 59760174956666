import Collapsible from "../Collapsible";

import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import WorksheetTable from "./WorksheetTable";

export default function CollapsibleWorksheet({ worksheet }) {
  return (
    <Collapsible
      header={(
        <div style={{ display: 'flex', gap: 8 }}>
          <Chip color='primary' label={worksheet.displayStartDate} />
          {worksheet.displayEndDate ?
            <Chip color='primary' label={worksheet.displayEndDate} /> :
            <Chip color='error' label='Clock-Out Pending' />}
        </div>
      )}
      disabled={!worksheet.workSheetRow}>

      {worksheet.workSheetRow && <WorksheetTable rows={worksheet.workSheetRow} />}

      {worksheet.hrnotes && (
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'start', marginTop: 12 }}>
          <Typography fontWeight='bold'>HR Notes</Typography>
          <Typography>{worksheet.hrnotes}</Typography>
        </div>
      )}
    </Collapsible>
  );
}