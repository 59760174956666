import * as React from 'react';

import Layout from '../../layout';
import CollapsibleWorksheet from '../components/worksheet/CollapsibleWorksheet';

import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';

import Form from "react-bootstrap/Form";

import { monthNames } from '../../lib/const';
import api from '../../lib/api';
import { totalWorkHours } from '../../lib/utils';

import { useParams } from 'react-router-dom';

export default function EmployeeDetail() {
  const now = new Date();
  const [searchMonth, setSearchMonth] = React.useState(now.getMonth() + 1);
  const [worksheets, setWorksheets] = React.useState([]);

  const { id } = useParams();

  function fetchWorksheets(year, month) {
    api.get(`/worksheet/getByEmployeeDateWise/${year}/${month}/${id}`)
      .then(({ data }) => setWorksheets(data));
  }

  function handleSearchMonthChange(e) {
    const monthNum = e.target.value;
    setSearchMonth(monthNum);
    fetchWorksheets(now.getFullYear(), monthNum);
  }

  React.useEffect(() => {
    fetchWorksheets(now.getFullYear(), searchMonth);
  }, []);

  return (
    <Layout title='Employee Worksheets'>
      <Paper style={{ padding: 24, minHeight: 600, margin: 100 }} elevation={4}>

        {/* search fields */}
        <div style={{ display: 'flex', gap: 12, maxWidth: 600 }}>
          <Form.Select
            value={now.getFullYear()}
            disabled
          >
            <option value={now.getFullYear()}>{now.getFullYear()}</option>
          </Form.Select>
          <Form.Select
            onChange={handleSearchMonthChange}
            value={searchMonth}
          >
            {monthNames.map((name, i) => (
              <option key={name} value={i + 1}>
                {name}
              </option>
            ))}
          </Form.Select>
        </div>

        {/* chips */}
        <div style={{ display: 'flex', marginTop: 24, }}>
          <Chip color='secondary' label={`${totalWorkHours(worksheets)}/160 Hours worked`} />
        </div>

        {/* worksheets */}
        <div style={{ marginTop: 24 }}>
          {worksheets.map((worksheet) => (
            <CollapsibleWorksheet
              key={worksheet.worksheetstartdate}
              worksheet={worksheet} />
          ))}
        </div>
      </Paper>
    </Layout>
  );
}