import { Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import user from "../assets/images/usericon.png";
import LogoutIcon from "@mui/icons-material/Logout";

export default function ProfileMenu({ title }) {
  return (
    <div className="page-title col-lg-12 row">
      <div className="col-lg-4">
        <h4 className="mt-2 text-center head">{title}</h4>
      </div>

      <div className="col-lg-2 text-end notification"></div>
      <div className="col-lg-1 text-end notification"></div>
      <div className="col-lg-2 text-end notification">
        {/* <img src={notification} style={{ cursor: "pointer" }} /> */}
      </div>

      <div className="col-lg-3 text-end">
        <Dropdown>
          <Dropdown.Toggle variant="secondar" id="dropdown-basic" size="sm">
            <span style={{ fontSize: "18px" }}>
              <img src={user} alt="" />
              <span> </span>
              {localStorage.getItem("userName")}
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="/profile" eventKey="1">
              My Profile
            </Dropdown.Item>
            <Divider />
            <Divider />
            <Dropdown.Item href="/profile" eventKey="1">
              Settings
            </Dropdown.Item>
            <Divider />
            <Divider />
            <Dropdown.Item href="/profile" eventKey="1">
              Setup Two Factor Auth
            </Dropdown.Item>
            <Divider />
            <Divider />
            <Dropdown.Item href="/logout" eventKey="1">
              <LogoutIcon /> Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}
