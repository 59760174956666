import React, { useEffect, useState } from "react";
// import api from "../../lib/api";
import { useNavigate } from "react-router-dom";
import { Controller, useController, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Layout from "../../layout";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import SendIcon from "@mui/icons-material/Send";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import DehazeIcon from "@mui/icons-material/Dehaze";
import InboxMessage from "./inboxMessage";
// import "./list.css";

const SignupSchema = yup.object().shape({
  to: yup.string().required("Please select To field"),
  subject: yup.string().required("Please select subject field"),
  body: yup.string().required("Please select body field"),
});

export default function TSMessage() {
  const [show, setShow] = useState(false);
  const [selectData, setSelectData] = useState();
  const [showDialog, setShowDialog] = useState({
    open: false,
    title: "",
  });
  const [inboxData, setInboxData] = useState([
    {
      name: "Mohammmed",
      subject: " review and sign the attached agreement",
      message:
        "Hi mohammed, Ullamco deserunt commodo esse deserunt deserunt quis eiusmod. Laborum sint excepteur non sit eiusmod sunt voluptate ipsum nisi ullamco magna. Lorem consectetur est dolor minim exercitation deserunt quis duis fugiat ipsum incididunt non. Anim aute ipsum cupidatat nisi occaecat quis sit nisi labore labore dolore do.",
    },
    {
      name: "Myra ",
      subject: "Urgent Required",
      message:
        "Hi , Ullamco deserunt commodo esse deserunt deserunt quis eiusmod. Laborum sint excepteur non sit eiusmod sunt voluptate ipsum nisi ullamco magna. Lorem consectetur est dolor minim exercitation deserunt quis duis fugiat ipsum incididunt non. Anim aute ipsum cupidatat nisi occaecat quis sit nisi labore labore dolore do.",
    },
    {
      name: " Dudley",
      subject: "Request",
      message:
        "Ullamco deserunt commodo esse deserunt deserunt quis eiusmod. Laborum sint excepteur non sit eiusmod sunt voluptate ipsum nisi ullamco magna. Lorem consectetur est dolor minim exercitation deserunt quis duis fugiat ipsum incididunt non. Anim aute ipsum cupidatat nisi occaecat quis sit nisi labore labore dolore do.",
    },
  ]);
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignupSchema),
    defaultValues: {
      projectName: "",
      category: "",
      ticketNumber: "",
      workHours: "",
      details: "",
    },
  });

  const navigate = useNavigate();
  const closeDialog = () => {
    setShowDialog((prev) => ({ ...prev, open: false }));
    reset({
      projectName: "",
      category: "",
      ticketNumber: "",
      workHours: "",
      details: "",
    });
  };

  return (
    <>
      <Layout title={"HR Messages"}>
        <div className="flex-lg-1 main-container  overflow-y-lg-auto">
          <main className="main-data ">
            <Paper
              style={{ padding: 24, minHeight: 600, margin: 100 }}
              elevation={4}
            >
              <div className="row col-lg-12">
                <div className="col-lg-3 col-md-3 mail">
                  <div>
                    {/* <h2>Connect with HR - Coming Soon</h2> */}
                    <h2 className="text-start ms-3">MailBox</h2>
                  </div>
                  <div className="text-start mt-4">
                    <button
                      className="button-34 "
                      onClick={() => {
                        setShowDialog((prev) => ({
                          ...prev,
                          open: true,
                          title: "New Message",
                          // handleSubmit: handleAddLeaves,
                        }));
                        reset();
                      }}
                    >
                      {" "}
                      <AddIcon /> Compose
                    </button>
                  </div>
                  <div className="text-start my-5 ms-3">
                    <h6 style={{ color: "#5e5df0" }}>MAILBOXES</h6>
                    <ul className="nav nav-pills nav-flush flex-column mb-auto text-start m-hide">
                      <li className="mail-txt ps-3">
                        <a
                          href="/messages/inbox"
                          className="nav-link rounded-0 "
                          title="Inbox"
                          data-bs-toggle="tooltip"
                          data-bs-placement="right"
                        ></a>
                        <AllInboxIcon className="pb-1" />{" "}
                        <span className="ms-2 pb-5">Inbox</span>
                      </li>
                      <li className="mail-txt mt-3 ps-3">
                        <a
                          href="/"
                          className="nav-link  rounded-0"
                          title="Sent"
                          data-bs-toggle="tooltip"
                          data-bs-placement="right"
                        ></a>
                        <SendIcon className="pb-1" />{" "}
                        <span className="ms-2 pb-5">Sent</span>
                      </li>
                      <li className="mail-txt mt-3 ps-3">
                        <a
                          href="/"
                          className="nav-link  rounded-0"
                          title="Draft"
                          data-bs-toggle="tooltip"
                          data-bs-placement="right"
                        ></a>
                        <InsertDriveFileIcon className="pb-1" />{" "}
                        <span className="ms-2 pb-5">Draft</span>
                      </li>
                      <li className="mail-txt mt-3 ps-3">
                        <a
                          href="/"
                          className="nav-link  rounded-0"
                          title="Spam"
                          data-bs-toggle="tooltip"
                          data-bs-placement="right"
                        ></a>
                        <GppMaybeIcon className="pb-1" />{" "}
                        <span className="ms-2 pb-5">Spam</span>
                      </li>
                      <li className="mail-txt mt-3 ps-3">
                        <a
                          href="/"
                          className="nav-link  rounded-0"
                          title="Trash"
                          data-bs-toggle="tooltip"
                          data-bs-placement="right"
                        ></a>
                        <DeleteOutlineIcon className="pb-1" />{" "}
                        <span className="ms-2 pb-5">Trash</span>
                      </li>
                    </ul>
                  </div>
                  <div className="text-start my-5 ms-3">
                    <h6 style={{ color: "#5e5df0" }}>MAILBOXES</h6>
                    <ul className="nav nav-pills nav-flush flex-column mb-auto text-start m-hide">
                      <li className="mail-txt ps-3">
                        <a
                          href="/"
                          className="nav-link rounded-0 "
                          title="Inbox"
                          data-bs-toggle="tooltip"
                          data-bs-placement="right"
                        ></a>
                        <AllInboxIcon className="pb-1" />{" "}
                        <span className="ms-2 pb-5">Inbox</span>
                      </li>
                      <li className="mail-txt mt-3 ps-3">
                        <a
                          href="/"
                          className="nav-link  rounded-0"
                          title="Sent"
                          data-bs-toggle="tooltip"
                          data-bs-placement="right"
                        ></a>
                        <SendIcon className="pb-1" />{" "}
                        <span className="ms-2 pb-5">Sent</span>
                      </li>
                      <li className="mail-txt mt-3 ps-3">
                        <a
                          href="/"
                          className="nav-link  rounded-0"
                          title="Draft"
                          data-bs-toggle="tooltip"
                          data-bs-placement="right"
                        ></a>
                        <InsertDriveFileIcon className="pb-1" />{" "}
                        <span className="ms-2 pb-5">Draft</span>
                      </li>
                      <li className="mail-txt mt-3 ps-3">
                        <a
                          href="/"
                          className="nav-link  rounded-0"
                          title="Spam"
                          data-bs-toggle="tooltip"
                          data-bs-placement="right"
                        ></a>
                        <GppMaybeIcon className="pb-1" />{" "}
                        <span className="ms-2 pb-5">Spam</span>
                      </li>
                      <li className="mail-txt mt-3 ps-3">
                        <a
                          href="/"
                          className="nav-link  rounded-0"
                          title="Trash"
                          data-bs-toggle="tooltip"
                          data-bs-placement="right"
                        ></a>
                        <DeleteOutlineIcon className="pb-1" />{" "}
                        <span className="ms-2 pb-5">Trash</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  className="col-lg-4 col-md-3 box-2"
                  style={{ borderRight: "1px solid rgba(0, 0, 0, 0.167)" }}
                >
                  <div
                    className="text-start ms-3 mb-3 "
                    id="drawer-toggle-label"
                  >
                    <DehazeIcon
                      onClick={() => {
                        alert("hh");
                        setShow(true);
                      }}
                      sx={{ cursor: "pointer" }}
                    />
                    <span
                      className="ms-5"
                      style={{ color: "black", fontWeight: "500" }}
                    >
                      INBOX
                    </span>
                  </div>
                  {inboxData.map((item, index) => (
                    <>
                      <div
                        key={index}
                        className="inbox text-start "
                        onClick={() => {
                          setSelectData(item);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <h6 className="mt-3 mx-2"> {item.name}</h6>
                        <p className="mx-2">{item.subject}</p>
                        <p className="inbox-msg mx-2">{item.message} </p>
                      </div>
                    </>
                  ))}
                  {/* <Inbox inboxData={inboxData} /> */}
                </div>
                <div className="col-lg-5 col-md-6 big-screen">
                  <InboxMessage message={selectData} />
                </div>
              </div>
            </Paper>
            <div className="dialog">
              <Dialog
                fullWidth
                open={showDialog.open}
                onClose={closeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  style: {
                    backgroundColor: "white",
                    boxShadow: "none",
                    borderRadius: "15px",
                  },
                }}
              >
                <DialogTitle
                  className="text-start text-white"
                  id="alert-dialog-title"
                  style={{
                    fontSize: "28px",
                    fontWeight: "600",
                    backgroundColor: "#5e5df0",
                  }}
                >
                  {"New Message"}
                </DialogTitle>
                <form onSubmit={handleSubmit(showDialog.handleSubmit)}>
                  <DialogContent>
                    <div className="form-field ticket-field mb-2">
                      <TextField
                        {...register("to")}
                        label="To"
                        sx={{
                          width: 530,
                          borderColor: errors.to && "red",
                        }}
                      />

                      {errors.to && (
                        <span className="text-danger mx-2">
                          {errors.to.message}
                        </span>
                      )}
                    </div>
                    <div className="form-field ticket-field mb-2 mt-4">
                      <TextField
                        {...register("subject")}
                        label="Subject"
                        sx={{
                          width: 530,
                          borderColor: errors.subject && "red",
                        }}
                      />

                      {errors.subject && (
                        <span className="text-danger mx-2">
                          {errors.subject.message}
                        </span>
                      )}
                    </div>
                    <div className="form-field mt-3">
                      {/* <label>Leave Reason</label> */}

                      <textarea
                        style={{ borderColor: errors.body && "red" }}
                        {...register("body")}
                        type="text"
                        className="form-control"
                        placeholder="Body"
                        // onChange={(e) => {
                        //   handleChange(e.target.value, "body");
                        // }}
                        rows={4}
                        multiline={true}
                      />
                      {errors.body && (
                        <p className="text-danger mx-2">
                          {errors.body.message}
                        </p>
                      )}
                    </div>
                  </DialogContent>
                  <DialogActions>
                    {/* <button onClick={closeDialog} className="btn btn-secondary">
                Cancel
              </button> */}
                    <div className="start-btn1 mx-2">
                      <button
                        autoFocus
                        className="btn btn-outline-danger"
                        onClick={closeDialog}
                      >
                        Cancel
                      </button>
                    </div>

                    {/* <button
                onClick={handleAddWorksheet}
                autoFocus
                className="btn btn-primary"
              >
                Add
              </button> */}
                    <div className="start-btn2 mx-2" style={{}}>
                      <button
                        type="submit"
                        autoFocus
                        className="btn btn-outline-dark"
                        // onClick={handleAddWorksheet}
                      >
                        Save
                      </button>
                    </div>
                  </DialogActions>
                </form>
              </Dialog>
            </div>{" "}
          </main>
        </div>
      </Layout>
    </>
  );
}
