import * as React from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useController } from "react-hook-form";
export default function DateField(props) {
  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController(props);
  //   const { label, value } = props;
  console.log(props);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker", "DatePicker"]}>
          <DatePicker
            // label={label}
            // value={dayjs(value)}
            // onChange={(newValue) => field.(newValue)}
            {...props}
            {...field}
            value={dayjs(field.value)}
            onChange={(e) => {
              // console.log(e);
              field.onChange(dayjs(e).format("YYYY-MM-DD"));
            }}
            format="DD-MM-YYYY"
          />
        </DemoContainer>
      </LocalizationProvider>
    </>
  );
}
