import React, { useEffect, useState } from "react";
import { Controller, useController, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import api from "../../lib/api";
import { useNavigate } from "react-router-dom";
import Layout from "../../layout";
import moment from "moment";
import { Paper } from "@mui/material";
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import clockin from "../../assets/images/clockin-removebg-preview.png";
import start from "../../assets/images/timer.png";
import clockout from "../../assets/images/timer (1).png";
import addtask from "../../assets/images/add.png";
import arrow from "../../assets/images/Vector.png";
import EditIcon from "@mui/icons-material/Edit";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
// import CardHeader from "react-bootstrap/esm/CardHeader";
import { Button, Card } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import Dropdown from "react-bootstrap/Dropdown";
import { GridDeleteIcon } from "@mui/x-data-grid";

function RHFAutoComplete({ name, control, label, options }) {
  const {
    field,
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules: { required: true },
  });

  return (
    <Autocomplete
      PaperProps={{ borderColor: error && "red" }}
      disablePortal
      options={options}
      sx={{ width: 550, borderColor: error && "red" }}
      renderInput={(params) => <TextField {...params} label={label} />}
      value={field.value}
      onChange={(e, nv) => field.onChange(nv)}
      onBlur={field.onBlur}
      ref={field.ref} // Check this
    />
  );
}

const SignupSchema = yup.object().shape({
  projectName: yup.string().required("Please select project name"),
  category: yup.string().required("Please select category"),
  //ticketNumber: yup.string().required("Ticket Number Field required"),
  workHours: yup.number().integer().required("Please provide work hour/hours"),
  details: yup.string().required("Description Field required"),
  // .min(100, "Minimum 100 Characters"),
});

export default function Worksheet() {
  const navigate = useNavigate();
  const [worksheetDetails, setWorksheetDetails] = useState();
  const [worksheetId, setWorkSheetId] = useState("");
  const [loggedInTime, setLoggedInTime] = useState();
  const [hrNotes, setHrNotes] = useState("");
  const [details, setDetails] = useState({
    projectName: "",
    ticketNumber: "",
    details: "",
    workHours: "",
    workSheetId: "",
    category: "",
  });
  const [error, setError] = useState({
    projectName: "",
    ticketNumber: "",
    details: "",
    workHours: "",
    category: "",
  });

  const [showDialog, setShowDialog] = useState({
    open: false,
    title: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [worksheetList, setWorksheetList] = useState([]);
  const [projects, setProjects] = useState([]);

  const handleStart = async (e) => {
    e.preventDefault();

    let currentDate = new Date().toISOString();
    let date = currentDate.substring(0, 10);
    let time = currentDate.substring(11, 19);
    try {
      const { data } = await api.post("/worksheet/start", {
        workSheetStartDateAsString: date,
        workSheetStartTimeAsString: time,
      });
      setWorksheetDetails(data);
      setLoggedInTime(data.worksheetstartdate);
      setWorkSheetId(data.id);
      setProjects(data.projects);
    } catch (error) {
      console.log(error);
    }
    // }
  };
  const handleEnd = async (e) => {
    e.preventDefault();
    // setIsSubmitted(true);
    // const isValidated = handleValidation();
    // if (isValidated) {
    let currentDate = new Date().toISOString();
    let date = currentDate.substring(0, 10);
    let time = currentDate.substring(11, 19);
    try {
      const { data } = await api.put("/worksheet/end", {
        id: worksheetId,
        workSheetEndDateAsString: date,
        workSheetEndTimeAsString: time,
        hrnotes: hrNotes,
      });

      setWorksheetDetails({ ...worksheetDetails, ...data });
    } catch (error) {
      console.log(error);
    }
    // }
  };

  const handleDeleteWorksheet = async (e) => {
    try {
      const { data } = await api.post("/worksheet/remove", {
        // ...details,
        ...e,
        workSheetId: worksheetId,
      });
      setWorksheetDetails({ ...worksheetDetails, ...data });
      setWorksheetList(data.workSheetRow);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddWorksheet = async (e) => {
    //e.preventDefault();
    console.log("Shab", e);
    setIsSubmitted(true);

    try {
      const { data } = await api.put("/worksheet/update", {
        // ...details,
        ...e,
        workSheetId: worksheetId,
      });

      setWorksheetDetails({ ...worksheetDetails, ...data });
      setWorksheetList(data.workSheetRow);
      closeDialog();
      reset();
    } catch (error) {
      console.log(error);
    }
    // }
  };

  const fetchData = async (e) => {
    let currentDate = new Date().toISOString();
    let date = currentDate.substring(0, 10);
    let time = currentDate.substring(11, 19);
    try {
      const { data, status } = await api.get(`/worksheet/init/${date}`);
      if (status == 200) {
        setWorksheetList(data.workSheetRow);
        setWorksheetDetails(data);
        setLoggedInTime(data.worksheetstartdate);
        setWorkSheetId(data.id);
        setProjects(data.projects);
        console.log("Projects  : " + projects);
      }
    } catch (error) {
      // alert(error);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const formatDate = () => {
    let d = loggedInTime;
    let formattedDate = moment(d).format("MMMM Do YYYY, h:mm:ss a");
    return formattedDate;
  };
  const handleDialog = () => {
    setShowDialog(() => ({ open: true }));
  };

  const closeDialog = () => {
    setShowDialog((prev) => ({ ...prev, open: false }));
    reset({
      projectName: "",
      category: "",
      ticketNumber: "",
      workHours: "",
      details: "",
    });
  };
  const handleChange = (value, key) => {
    setDetails({ ...details, [key]: value });
    // if (isSubmitted) {
    //   handleValidation();
    // }
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignupSchema),
    defaultValues: {
      projectName: "",
      category: "",
      ticketNumber: "",
      workHours: "",
      details: "",
    },
  });

  const name = localStorage.getItem("userData");
  return (
    <Layout title={"Today Worksheet"}>
      <div className="flex-lg-1 main-container  overflow-y-lg-auto">
        <main className="main-data">
          <Paper
            style={{ padding: 24, minHeight: 600, margin: 100 }}
            elevation={4}
          >
            <div className="container-fluid" style={{ maxWidth: "100%" }}>
              <div className="row justify-content-center">
                <div className="col-lg-12 ">
                  <div className="time-start-wrapper1">
                    <div className="start-btnn">
                      {!worksheetDetails ? (
                        <div className="">
                          <img src={clockin} alt="" />
                          <h5 className="text-center text">Welcome! </h5>
                          <p className="text-center mt-1 text">
                            Clock in and make the most of your shift!
                          </p>
                          <button
                            className="btn btn-dark "
                            onClick={handleStart}
                            style={{
                              height: 75,
                              width: 250,
                              fontSize: 26,
                              fontWeight: 600,
                              marginTop: 25,
                            }}
                          >
                            Clock In{" "}
                          </button>
                          <p
                            className="text-center mt-4"
                            style={{ fontWeight: "600px" }}
                          ></p>
                        </div>
                      ) : (
                        <div>
                          {/* <button disabled className="btn btn-primary">
                          In Progress
                        </button> */}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mt-2 mb-5 row mx-4 justify-content-center ">
                  <div className="col-lg-4 ">
                    {loggedInTime && (
                      <div className="">
                        <Card
                          className="my-2 clockin-card"
                          style={{
                            width: "100%",
                            height: "237px",
                            backgroundColor: "#f1e7f9",
                          }}
                        >
                          <Card.Title className="text-start mt-4 mx-3">
                            <img
                              src={start}
                              alt=""
                              style={{ height: "72px" }}
                            />
                          </Card.Title>
                          <Card.Title
                            className="text-start mx-3 mt-2"
                            style={{ fontSize: "28px" }}
                          >
                            Clock In
                          </Card.Title>

                          <Card.Text className="text-start mx-3">
                            {worksheetDetails?.displayStartDate}
                          </Card.Text>

                          <Card.Text className="text-start mx-3">
                            {/* <span
                                className={
                                  worksheetDetails?.late
                                    ? "red-circle1 text-center d-flex"
                                    : "green-circle1 text-center d-flex "
                                }
                              ></span> */}
                            {worksheetDetails?.late ? (
                              <Badge
                                pill
                                className="late-badge"
                                bg=""
                                text=""
                                style={{
                                  backgroundColor: "#FFD0D0",
                                  color: "#FF0000",
                                }}
                              >
                                Late
                              </Badge>
                            ) : (
                              <Badge
                                pill
                                className="late-badge"
                                bg=""
                                text=""
                                style={{
                                  backgroundColor: "#ECFFDC",
                                  color: "green",
                                }}
                              >
                                Success
                              </Badge>
                            )}
                          </Card.Text>
                          {/* <Card.Text
                            className=""
                            style={{ marginTop: "110px" }}
                          >
                            {worksheetDetails?.late ? (
                              <p className="note">
                                NOTE - Please try to check in before your shift
                                time.
                              </p>
                            ) : (
                              <p></p>
                            )}
                          </Card.Text> */}
                        </Card>
                      </div>
                    )}
                  </div>
                  {worksheetDetails ? (
                    <div className="col-lg-4">
                      {!worksheetDetails?.worksheetenddate &&
                      worksheetDetails ? (
                        <div className="">
                          {/* <p className="time text-center mb-4">
                    End at:{" "}
                    <Chip
                      label={worksheetDetails?.displayEndDate}
                      style={{ fontSize: "1.1em" }}
                    />
                  </p> */}
                          <Card
                            className="my-2 clockin-card"
                            style={{
                              width: "100%",
                              height: "237px",
                              backgroundColor: "#eee1e7",
                            }}
                          >
                            <Card.Title className="text-start mt-4 mx-3">
                              <img
                                src={clockout}
                                alt=""
                                style={{ height: "72px" }}
                              />
                            </Card.Title>

                            <div className="col-lg-12 row">
                              <div className="col-lg-10">
                                <Card.Title
                                  className="text-start mx-3 mt-4"
                                  style={{ fontSize: "28px" }}
                                >
                                  Clock Out
                                </Card.Title>
                              </div>
                              <div
                                className="col-lg-2 arrow"
                                style={{ marginTop: "20px" }}
                              >
                                <img
                                  style={{ cursor: "pointer" }}
                                  src={arrow}
                                  alt=""
                                  onClick={handleEnd}
                                />
                              </div>
                            </div>
                            {/* <Card.Title
                          className="text-start mx-3 mt-4"
                          style={{ fontSize: "24px" }}
                        >
                          Clock Out
                        </Card.Title>

                        <Card.Text className="text-start mx-3">
                          {worksheetDetails?.displayEndDate}
                        </Card.Text> */}
                            {/* <Card.Text>
                          <div className="row col-lg-12 mt-4">
                            <div className="col-lg-4 mt-1 ">
                              <span style={{ fontSize: "20px" }}>
                                Status :{" "}
                              </span>
                            </div>
                            <div
                              className="col-lg-8 mt-1"
                              style={{ padding: "0px" }}
                            >
                              <span
                                className={
                                  worksheetDetails?.late
                                    ? "green-circle1 text-center d-flex "
                                    : "red-circle1 text-center d-flex "
                                }
                              ></span>
                            </div>
                          </div>
                        </Card.Text> */}
                            {/* <Card.Text>
                          <div className="row col-lg-12 mt-2 justify-content-end">
                            <div className="col-lg-1 mt-1">
                              <span
                                className={
                                  worksheetDetails?.late
                                    ? "green-circle1 text-center d-flex "
                                    : "red-circle1 text-center d-flex"
                                }
                              ></span>
                            </div>
                            <div className="col-lg-11 mt-1 ">
                              <Badge
                                pill
                                className="late-badge"
                                bg=""
                                text=""
                                style={{
                                  backgroundColor: "#FFD0D0",
                                  color: "#FF0000",
                                }}
                              >
                                Status
                              </Badge>
                            </div>
                          </div>
                        </Card.Text> */}
                            {/* <Card.Text>
                          <div className="row col-lg-12 mt-4">
                            <div className="col-lg-6 mt-1">
                              <span
                                style={{
                                  fontSize: "20px",
                                  paddingLeft: "20px",
                                }}
                              >
                                Total Hours :{" "}
                                <Chip
                                  label={worksheetDetails?.workSheetHours}
                                  style={{ fontSize: "1.1em" }}
                                />
                              </span>
                            </div>
                          </div>
                        </Card.Text> */}

                            {/* <Card.Text className="mt-5">
                          {worksheetDetails?.late ? (
                            <p></p>
                          ) : (
                            <p className="mt-3 note">
                              NOTE - You have work less hours than your shift.
                            </p>
                          )}
                        </Card.Text> */}
                          </Card>
                        </div>
                      ) : (
                        <Card
                          className="my-2 clockin-card"
                          style={{
                            width: "100%",
                            height: "237px",
                            backgroundColor: "#d8dce5",
                          }}
                        >
                          <Card.Title className="text-start mt-4 mx-3">
                            <img
                              src={clockout}
                              alt=""
                              style={{ height: "72px" }}
                            />
                          </Card.Title>

                          {/* <div className="col-lg-12 row">
                      <div className="col-lg-10">
                        <Card.Title
                          className="text-start mx-3 mt-4"
                          style={{ fontSize: "28px" }}
                        >
                          Clock Out
                        </Card.Title>
                      </div>
                      <div
                        className="col-lg-2 arrow"
                        style={{ marginTop: "20px" }}
                      >
                        <img
                          style={{ cursor: "pointer" }}
                          src={arrow}
                          alt=""
                          onClick={handleEnd}
                        />
                      </div>
                    </div> */}
                          <Card.Title
                            className="text-start mx-3 mt-2"
                            style={{ fontSize: "24px" }}
                          >
                            Clock Out
                          </Card.Title>

                          <Card.Text className="text-start mx-3">
                            {worksheetDetails?.displayEndDate}
                          </Card.Text>
                          {/* <Card.Text>
                        <div className="row col-lg-12 mt-4">
                          <div className="col-lg-4 mt-1 ">
                            <span style={{ fontSize: "20px" }}>Status : </span>
                          </div>
                          <div
                            className="col-lg-8 mt-1"
                            style={{ padding: "0px" }}
                          >
                            <span
                              className={
                                worksheetDetails?.late
                                  ? "green-circle1 text-center d-flex "
                                  : "red-circle1 text-center d-flex "
                              }
                            ></span>
                          </div>
                        </div>
                      </Card.Text> */}
                          {/* <Card.Text className="text-start mx-3">
                        {worksheetDetails?.late ? (
                          <Badge
                            pill
                            className="late-badge"
                            bg=""
                            text=""
                            style={{
                              backgroundColor: "#FFD0D0",
                              color: "#FF0000",
                            }}
                          >
                            Late
                          </Badge>
                        ) : (
                          <Badge
                            pill
                            className="late-badge"
                            bg=""
                            text=""
                            style={{
                              backgroundColor: "#ECFFDC",
                              color: "green",
                            }}
                          >
                            Success
                          </Badge>
                        )}
                      </Card.Text> */}
                          {/* <Card.Text>
                      <div className="row col-lg-12 mt-4">
                        <div className="col-lg-6 mt-1">
                          <span
                            style={{
                              fontSize: "20px",
                              paddingLeft: "20px",
                            }}
                          >
                            Total Hours :{" "}
                            <Chip
                              label={worksheetDetails?.workSheetHours}
                              style={{ fontSize: "1.1em" }}
                            />
                          </span>
                        </div>
                      </div>
                    </Card.Text> */}

                          {/* <Card.Text className="mt-5">
                      {worksheetDetails?.late ? (
                        <p></p>
                      ) : (
                        <p className="mt-3 note">
                          NOTE - You have work less hours than your shift.
                        </p>
                      )}
                    </Card.Text> */}
                        </Card>
                        // <div></div>
                      )}
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div className="col-lg-4">
                    {worksheetDetails &&
                      !worksheetDetails?.worksheetenddate && (
                        <div className="">
                          {/* <button className="btn btn-primary" onClick={handleDialog}>
                    Add Task
                  </button> */}
                          <Card
                            className="my-2 clockin-card"
                            style={{
                              width: "100%",
                              height: "237px",
                              backgroundColor: "#ced9d0",
                            }}
                          >
                            <Card.Title className="text-start mt-4 mx-3">
                              <img
                                src={addtask}
                                alt=""
                                style={{ height: "72px" }}
                              />
                            </Card.Title>
                            <div className="col-lg-12 row">
                              <div className="col-lg-10">
                                <Card.Title
                                  className="text-start mx-3 mt-4"
                                  style={{ fontSize: "28px" }}
                                >
                                  Add a New Task
                                </Card.Title>
                              </div>
                              <div
                                className="col-lg-2 arrow"
                                style={{ marginTop: "20px" }}
                              >
                                <img
                                  style={{ cursor: "pointer" }}
                                  src={arrow}
                                  alt=""
                                  onClick={() => {
                                    setShowDialog((prev) => ({
                                      ...prev,
                                      open: true,
                                      title: "Add a New Task",
                                      handleSubmit: handleAddWorksheet,
                                    }));
                                    reset();
                                  }}
                                />
                              </div>
                            </div>
                          </Card>
                        </div>
                      )}
                  </div>
                </div>

                {/* {worksheetDetails?.worksheetenddate && (
                <div>
                  <div className="">
                    <p className="time text-center mb-4">
                      Worksheet Hours:{" "}
                      <Chip
                        label={worksheetDetails?.workSheetHours}
                        style={{ fontSize: "1.1em" }}
                      />
                    </p>
                  </div>
                  <div className="">
                    <p className="time text-center mb-4">
                      Task Hours?:{" "}
                      <Chip
                        label={worksheetDetails?.workSheetRowsHours}
                        style={{ fontSize: "1.1em" }}
                      />
                    </p>
                  </div>
                  <div className="">
                    <p className="time text-center mb-4">
                      Not Started on Shift Time?:{" "}
                      <Chip
                        label={worksheetDetails?.leftEarly}
                        style={{ fontSize: "1.1em" }}
                      />
                      HalfDay?:{" "}
                      <Chip
                        label={worksheetDetails?.leftEarly}
                        style={{ fontSize: "1.1em" }}
                      />
                    </p>
                  </div>
                  <div className="">
                    <p className="time text-center mb-4">
                      HalfDay?:{" "}
                      <Chip
                        label={worksheetDetails?.leftEarly}
                        style={{ fontSize: "1.1em" }}
                      />
                    </p>
                  </div>
                </div>
              )} */}
                {worksheetDetails ? (
                  <>
                    <h5
                      className="text-start mb-4"
                      style={{ fontSize: "24px" }}
                    >
                      Work Breakup
                    </h5>
                    <div className="row my-2 ">
                      <table class="table table-css table-bordered border-black">
                        <thead>
                          <tr>
                            <th scope="col" className="col1">
                              Project Name
                            </th>
                            <th scope="col" className="col1">
                              Category
                            </th>
                            <th scope="col" className="col2">
                              Ticket Number
                            </th>
                            <th scope="col" className="col3">
                              Hours
                            </th>
                            <th scope="col" className="col4">
                              Description
                            </th>
                            <th scope="col" className="col5">
                              Action
                            </th>
                          </tr>
                        </thead>
                        {worksheetList?.map((item) => {
                          return (
                            <tbody className="table-hover ">
                              <tr>
                                <td>{item.projectName}</td>
                                <td>{item.category}</td>
                                <td>{item.ticketNumber}</td>
                                <td>{item.workHours} Hours</td>
                                <td className="row4 text-start">
                                  {" "}
                                  {item.details}
                                </td>
                                <td>
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      variant="white"
                                      id="dropdown-basic"
                                    >
                                      <i className="fa-solid fa-ellipsis-vertical"></i>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() => {
                                          setShowDialog((prev) => ({
                                            ...prev,
                                            open: true,
                                            title: "Edit",
                                            handleSubmit: handleAddWorksheet,
                                          }));
                                          reset(item);
                                        }}
                                      >
                                        {" "}
                                        <EditIcon /> Edit
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() =>
                                          handleDeleteWorksheet(item)
                                        }
                                      >
                                        {" "}
                                        <GridDeleteIcon /> Delete
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              </tr>
                            </tbody>
                          );
                        })}
                      </table>
                      {/* <div className="col-lg-2">
                      <div className="box1">
                        <p className="proj-name text-start px-4 pt-3">
                          {item.projectName}
                        </p>
                        <div className="col-lg-12 row ">
                          <div className="col-lg-6">
                            <p className="ticket-no text-start px-4 mt-4">
                              {" "}
                              {item.ticketNumber}
                            </p>
                          </div>
                          <div className="col-lg-6 text-end hours-box">

                            <span className="hours text-end">
                              {" "}
                              {item.workHours} Hours
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Card className="col-lg-10 my-2">
                      <div className="box1">
                        <p className="details text-start mx-2">
                          {item.details}
                        </p>
                      </div>
                    </Card> */}
                    </div>
                  </>
                ) : (
                  <div></div>
                )}

                {/* <Box sx={{ height: 400, width: "100%" }}>
              </Box> */}
                {/* <DataGrid
                rows={worksheetList}
                getRowId={(row) => row.ticketNumber}
                columns={columns}
              /> */}
                {worksheetDetails && !worksheetDetails?.worksheetenddate && (
                  <div className="col-lg-12 mt-5">
                    <div className="card">
                      <div className="card-body">
                        <div className="hr-note">
                          <h3>Note to HR</h3>
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows="4"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="start-btn2 text-center mt-3" style={{}}>
                      <button
                        type="submit"
                        class="btn btn-dark btn-lg mb-5"
                        onClick={handleEnd}
                        style={{
                          height: 75,
                          width: 250,
                          fontSize: 26,
                          fontWeight: 600,
                        }}
                      >
                        Clock out
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* {!worksheetDetails?.worksheetenddate && worksheetDetails ? (
            <div>
              <div className="start-btn mt-2">
                <button className="btn btn-primary" onClick={handleEnd}>
                  End Time
                </button>
              </div>
            </div>
          ) : (
            <div></div>
          )} */}
          </Paper>
        </main>
        <div className="dialog">
          <Dialog
            fullWidth
            open={showDialog.open}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                backgroundColor: "white",
                boxShadow: "none",
              },
            }}
          >
            <DialogTitle
              className="text-center text-black"
              id="alert-dialog-title"
              style={{ fontSize: "28px", fontWeight: "600" }}
            >
              {"Add a New Task"}
            </DialogTitle>
            <form onSubmit={handleSubmit(showDialog.handleSubmit)}>
              <DialogContent>
                <div className="form-field">
                  <RHFAutoComplete
                    name="projectName"
                    label="Project Name"
                    control={control}
                    options={projects}
                  />
                  {errors.projectName && (
                    <p className="text-danger mx-2">
                      {errors.projectName.message}
                    </p>
                  )}
                  {/* {console.log(watch(), "www")} */}
                </div>

                <div className="form-field pt-3">
                  <RHFAutoComplete
                    name="category"
                    control={control}
                    label="Category"
                    options={[
                      "Development",
                      "QA",
                      "Tech Meeting",
                      "Standup",
                      "Client Meeting",
                      "Not at Desk - Away",
                    ]}
                  />
                  {errors.category && (
                    <p className="text-danger mx-2">
                      {errors.category.message}
                    </p>
                  )}
                  {/* {console.log(watch(), "www")} */}
                </div>
                {/* <div className="form-field">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => {
                    handleChange(e.target.value, "projectName");
                  }}
                >
                  <option selected>Project Name</option>
                  {worksheetDetails
                    ? worksheetDetails?.projects?.map((item) => (
                        <option value={item}>{item}</option>
                      ))
                    : ""}
                </select>
              </div> */}
                <div className="row col-lg-12 pt-3">
                  <div className="col-lg-6">
                    <div className="form-field ticket-field">
                      <input
                        style={{ borderColor: errors.ticketNumber && "red" }}
                        {...register("ticketNumber")}
                        type="text"
                        className="form-control"
                        id="ticket-number"
                        placeholder="Ticket Number"
                        // onChange={(e) => {
                        //   handleChange(e.target.value, "ticketNumber");
                        // }}
                      />
                      {errors.ticketNumber && (
                        <p className="text-danger mx-2">
                          {errors.ticketNumber.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-field">
                      <input
                        style={{ borderColor: errors.workHours && "red" }}
                        {...register("workHours")}
                        type="text"
                        className="form-control"
                        id="work-hours"
                        placeholder="Time in Hours"
                        // onChange={(e) => {
                        //   handleChange(e.target.value, "workHours");
                        // }}
                      />
                      {errors.workHours && (
                        <p className="text-danger mx-2">
                          {errors.workHours.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-field pt-3">
                  <textarea
                    style={{ borderColor: errors.details && "red" }}
                    {...register("details")}
                    type="text"
                    className="form-control"
                    id="description"
                    placeholder="Note"
                    // onChange={(e) => {
                    //   handleChange(e.target.value, "details");
                    // }}
                    rows={3}
                    multiline={true}
                  />
                  {errors.details && (
                    <p className="text-danger mx-2">{errors.details.message}</p>
                  )}
                </div>
              </DialogContent>
              <DialogActions>
                {/* <button onClick={closeDialog} className="btn btn-secondary">
                Cancel
              </button> */}
                <div className="start-btn1 mx-2">
                  <button
                    autoFocus
                    className="btn btn-secondary"
                    onClick={closeDialog}
                  >
                    Cancel
                  </button>
                </div>

                {/* <button
                onClick={handleAddWorksheet}
                autoFocus
                className="btn btn-primary"
              >
                Add
              </button> */}
                <div className="start-btn2 mx-2" style={{}}>
                  <button
                    type="submit"
                    autoFocus
                    className="btn btn-primary"
                    // onClick={handleAddWorksheet}
                  >
                    Save
                  </button>
                </div>
              </DialogActions>
            </form>
          </Dialog>
        </div>
      </div>
    </Layout>
  );
}
