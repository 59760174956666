import React, { useEffect, useState } from "react";
import api from "../../lib/api";
import { useNavigate } from "react-router-dom";
import Layout from "../../layout";
import { Paper, Typography } from "@mui/material";
import { useToast } from "../../lib/toastctx";


export default function EmployeeProfile() {
  const navigate = useNavigate();
  const [employee, setEmployee]  = useState();
  const {openToast} = useToast();
  const fetchProfile = async (e) => {
    try {
      const { data, status } = await api.get(`/employees/me/`);
      if (status == 200) {  
        setEmployee(data);
      }
    } catch (error) {
      // alert(error);
      openToast('error',error.response?.data?.message || 'Invalid Employee')
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);
  return (
    <>
    <Layout title={"My Profile"}>
      <div className="flex-lg-1 main-container  overflow-y-lg-auto">
        <main className="main-data">
        <Paper style={{ padding: 24, minHeight: 600, margin: 100 }} elevation={4}>
         <div>
          <h2>Employee Profile - Coming Soon</h2>
         </div>
         <div>
          <Typography>
          
          </Typography>
          <Typography>
           
          </Typography>
         </div>
         </Paper>
        </main>
      </div>
    </Layout>
    </>
  );
}