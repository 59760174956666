import React, { Fragment } from "react";
import Layout from "../../layout/index";
import Dropdown from "react-bootstrap/Dropdown";
// import user from "../../../assets/images/usericon.png";
import { useEffect, useState } from "react";
import api from "../../lib/api";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { Visibility as ViewIcon, Edit as EditIcon, MoreHoriz as MoreIcon } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import "../../layout/index.css";

import Input from "@mui/material/Input";
// import { motion } from "framer-motion";
import Button from "@mui/material/Button";
// import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
// import "./index.css";
import "../../layout/index";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { IconButton, List, Paper, Tooltip, Menu, MenuItem } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useToast } from "../../lib/toastctx";

function Options({ empId }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  function onClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function onClose() {
    setAnchorEl(null);
  }

  return (
    <Box
      sx={{ pr: 1 }}
    >
      <IconButton
        id="options-button"
        aria-controls={open ? 'options-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={onClick}
      >
        <MoreIcon />
      </IconButton>
      <Menu
        id="options-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{
          'aria-labelledby': 'options-button',
        }}
      >
        <MenuItem component={Link} to={`/employeelist/employeedetail/${empId}`}>
          Worksheet
        </MenuItem>
      </Menu>
    </Box>
  );
}

function EmployeeList() {
  const [empListFilter, setEmpListFilter] = useState({
    letterArr: [],
  });
  const [worksheetList, setWorksheetList] = useState([]);
  const [worksheetDetails, setWorksheetDetails] = useState();
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  const [data, setData] = useState([]);

  const navigate = useNavigate();
  const { openToast } = useToast();

  const fetchEmployeeData = async (e) => {
    try {
      const { data, status } = await api.get(`/employees`);
      if (status == 200) {
        console.log(data);
        setEmployeeDetails(data);
        let l = Array.from(
          new Set(data.map((item) => item.firstName.slice(0, 1)))
        ).sort();
        setEmpListFilter({
          letterArr: l,
        });
        searchItems("", data);
      }
    } catch (err) {
      // console.log(err);
      // const respCode = err?.response?.status;
      // if (respCode === 401 || respCode == 403) {
      //   openToast('error', 'Unauthorized');
      //   navigate('/report', { replace: true });
      // }
    }
  };
  useEffect(() => {
    fetchEmployeeData();
  }, []);

  const searchItems = (searchValue, allData) => {
    // setSearchInput(searchValue);

    if (searchValue !== "") {
      const filteredData = employeeDetails.filter((item) => {
        return Object.values(item.firstName)
          .join("")
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      console.log("ssss", searchValue);
      setFilteredResults(allData ? allData : employeeDetails);
    }
  };
  // const filteredData = employeeDetails.filter((item) => {
  //   return Object.values(item)
  //     .join("")
  //     .toLowerCase()
  //     .includes(searchInput.toLowerCase());
  // });

  return (
    <>
      <Layout title={"Employee"}>
        <div className="flex-lg-1 main-container  overflow-y-lg-auto">
          <main className="main-data">
            <Paper style={{ minHeight: 600, margin: 100 }} elevation={4}>
              <div className="container mt-5">
                {/* <SearchIcon className="icon mt-3 mx-2" /> */}
                <input
                  type="text"
                  className="input mt-4 mb-5"
                  placeholder="Search Employee"
                  onChange={(e) => {
                    searchItems(e.target.value);
                  }}
                />
                {/* <button
                  className="btn btn-primary mx-2"
                  style={{ borderRadius: "20px" }}
                >
                  <AddIcon /> Add
                </button> */}
              </div>
              <div className="relative">
                {empListFilter.letterArr.map((litem) => {
                  if (
                    !filteredResults.find(
                      (f) =>
                        f.firstName.charAt(0).toLowerCase() ===
                        litem.toLowerCase()
                    )
                  ) {
                    return <> </>;
                  }
                  return (
                    <>
                      <Typography
                        color="text-dark"
                        className="px-32 pb-4 text-14 font-medium text-start px-3 "
                        style={{
                          borderTop: "1px solid rgba(0, 0, 0, 0.167)",
                          backgroundColor: "#f1f5f9",
                          height: "10px",
                        }}
                      >
                        {litem}
                      </Typography>
                      {filteredResults.map((emp) => {
                        if (
                          litem.toLowerCase() ===
                          emp.firstName.charAt(0).toLowerCase()
                        ) {
                          return (
                            <ListItem
                              className="px-32 py-16 "
                              sx={{
                                bgcolor: "white",
                                borderTop: "1px solid rgba(0, 0, 0, 0.167)",
                              }}
                              secondaryAction={<Options empId={emp.id} />}
                            // component={NavLinkAdapter}
                            // to={`/apps/contacts/${contact.id}`}
                            >
                              <ListItemText
                                sx={{ pl: 1 }}
                                primary={emp.firstName}
                                secondary={emp.middleName}
                              />
                            </ListItem>
                          );
                        }
                      })}
                    </>
                  );
                })}

                <Divider />
                <List className="w-full m-0 p-0">
                  {/* {group.children.map((item) => (
                    <ContactListItem key={item.id} contact={item} />
                  ))} */}
                </List>
              </div>

              <Divider />
            </Paper>
          </main>
        </div>
      </Layout>
    </>
  );
}

export default EmployeeList;

// {searchInput.length > 1
//   ? filteredResults.map((item) => {
//       return (
//         <ListItem
//           className="px-32 py-16 "
//           sx={{
//             bgcolor: "white",
//             borderTop: "1px solid rgba(0, 0, 0, 0.167)",
//           }}
//           button
//           // component={NavLinkAdapter}
//           // to={`/apps/contacts/${contact.id}`}
//         >

//           <ListItemText
//             classes={{
//               root: "m-0",
//               primary: "font-medium leading-5 truncate px-3",
//             }}
//             primary={item.firstName}
//             secondary={
//               <>
//                 <Typography
//                   className="inline px-3"
//                   component="span"
//                   variant="body2"
//                   color="text.secondary"
//                 >
//                   {item.middleName}
//                 </Typography>
//               </>
//             }
//           />
//         </ListItem>
//       );
//     })
//   : employeeDetails.map((item) => {
//       return (
//         <ListItem
//           className="px-32 py-16 "
//           sx={{
//             bgcolor: "white",
//             borderTop: "1px solid rgba(0, 0, 0, 0.167)",
//           }}
//           button
//           // component={NavLinkAdapter}
//           // to={`/apps/contacts/${contact.id}`}
//         >

//           <ListItemText
//             classes={{
//               root: "m-0",
//               primary: "font-medium leading-5 truncate px-3",
//             }}
//             primary={item.firstName}
//             secondary={
//               <>
//                 <Typography
//                   className="inline px-3"
//                   component="span"
//                   variant="body2"
//                   color="text.secondary"
//                 >
//                   {item.middleName}
//                 </Typography>
//               </>
//             }
//           />
//         </ListItem>
//       );
//     })}
