import { Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, IconButton, Chip, Menu, MenuItem, TextField, Box, InputAdornment } from '@mui/material';
import { MoreHoriz, Search } from '@mui/icons-material'
import { useEffect, useState } from 'react';
import api from '../../lib/api';
import Layout from '../../layout';

function Options({ onApprove, onCancel }) {
	const [anchorEl, setAnchorEl] = useState(null);

	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	function wrapAction(func) {
		return async () => {
			await func();
			handleClose();
		};
	}

	return (
		<div>
			<IconButton onClick={handleClick}>
				<MoreHoriz />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				<MenuItem onClick={wrapAction(onApprove)}>Approve</MenuItem>
				<MenuItem onClick={wrapAction(onCancel)}>Cancel</MenuItem>
			</Menu>
		</div>
	);
}

const statusColors = {
	'Approved': 'success',
	'Cancelled': 'error',
	'Pending': 'warning',
	'Withdrawn': 'error',
};

function SearchField({ value, onChange }) {
	return (
		<TextField
			sx={{ minWidth: 300 }}
			variant='standard'
			placeholder='Search'
			InputProps={{
				startAdornment: <InputAdornment position='start'> <Search /> </InputAdornment>
			}}
			value={value}
			onChange={onChange}
		/>
	);
}

export default function AllLeaves() {
	const [leaves, setLeaves] = useState([]);

	const [search, setSearch] = useState('');

	async function fetchAllLeaves() {
		const { data } = await api.get('/leaves/all');
		setLeaves(data);
	}

	useEffect(() => {
		fetchAllLeaves();
	}, []);

	// update local leave's status value instead of refetching
	function setLeaveStatus(id, status) {
		const cp = [...leaves];
		const old = cp.find(l => l.id === id);
		const i = cp.indexOf(old);
		cp[i] = { ...old, status };
		setLeaves(cp);
	}

	async function updateLeaveStatus(leaveId, status) {
		try {
			await api.put(`/leaves/${leaveId}/status?v=${status}`)
			setLeaveStatus(leaveId, status);
		} catch (error) {
			console.log(error);
		}
	}

	function onSearchChange(e) {
		setSearch(e.target.value);
	}

	const searchRegex = new RegExp(search, 'i')
	const searchLeaves = leaves.filter(l => searchRegex.test(Object.values(l).join()));

	return (
		<Layout title='All Leaves'>
			<Box
				sx={{ m: 12 }}
			>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-end',
						mb: 2
					}}
				>
					<SearchField
						value={search}
						onChange={onSearchChange}
					/>
				</Box>

				<TableContainer
					component={Paper}
					elevation={3}
				>
					<Table sx={{ minWidth: 650 }} aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>Employee</TableCell>
								<TableCell>Leave Type</TableCell>
								<TableCell>Start Date</TableCell>
								<TableCell>End Date</TableCell>
								<TableCell>No. Days</TableCell>
								<TableCell>Reason</TableCell>
								<TableCell>Status</TableCell>
								<TableCell>Action</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{searchLeaves.map((leave) => (
								<TableRow
									key={leave.name}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<TableCell>{leave.employeeName}</TableCell>
									<TableCell>{leave.leaveType}</TableCell>
									<TableCell>{leave.startDate}</TableCell>
									<TableCell>{leave.endDate}</TableCell>
									<TableCell>{leave.leaveDays}</TableCell>
									<TableCell>{leave.description}</TableCell>
									<TableCell><Chip label={leave.status} color={statusColors[leave.status]} /></TableCell>
									<TableCell>
										<Options
											onApprove={() => updateLeaveStatus(leave.id, 'Approved')}
											onCancel={() => updateLeaveStatus(leave.id, 'Cancelled')}
										/>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</Layout>
	);
}