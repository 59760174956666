import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./project.css";
import { Paper } from "@mui/material";
import Badge from "react-bootstrap/Badge";
import Dropdown from "react-bootstrap/Dropdown";
import { Button } from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import api from "../lib/api";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { DateRange, DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Layout from "../layout";

const SignupSchema = yup.object().shape({
  projectName: yup.string().required("Project Name Field required"),
  // category: yup.string().required("Category Name Field required"),
  // customerName: yup.string().required("Customer  Name Field required"),
  // projectPhase: yup.string().required("Project Phase Name Field required"),
  // status: yup.string().required("Status Name Field required"),
  // priority: yup.string().required("priority Name Field required"),
  // projectTimeline: yup.string().required("projectTimeline Name Field required"),
  // endDate: yup.string().required("endDate Name Field required"),
  // teamMember: yup.string().required("teamMember Name Field required"),
  // member: yup.string().required("member Name Field required"),

  // from: yup.string().required("Date is required"),
  // to: yup.string().required("Date is required"),
  // //ticketNumber: yup.string().required("Ticket Number Field required"),
  // days: yup.number().integer().required("No of days Field required"),
  // remainingLeaves: yup
  //   .number()
  //   .integer()
  //   .required("Remaining leaves Field required"),
  // details: yup.string().required("Reason Field required"),
});

function Project() {
  const [category, setCategory] = useState([
    "Web Application",
    "Mobile Application",
    "Hybrid Application",
    "Desktop Application",
  ]);
  const [status, setStatus] = useState([
    "Active",
    "InActive",
    "Completed",
    "Onhold",
    "Cancelled",
  ]);
  const [priority, setPriority] = useState(["Urgent", "Regular"]);
  const [customerName, setCustomerName] = useState([
    " HST Solutions",
    "K Business Inc ",
  ]);
  const [role, setRole] = useState([
    "BE Developer",
    "FE Developer",
    "Full Stack Developer",
    "QA",
    "UI/UX",
    "Project Manager",
    "Html/CSS",
    "Mobile Developer/Futter",
    "Mobile Developer/Native",
    "Trainee",
  ]);
  const [projectPhase, setProjectPhase] = useState([
    "Development",
    "UAT",
    "Live",
  ]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [teamMember, setTeamMember] = useState([]);
  const [inputData, setInputData] = useState("");
  const [memberData, setMemberData] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [employee, setEmployee] = useState([]);
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  const handleDialog = () => {
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
    reset();
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignupSchema),
  });

  const fetchData = async (e) => {
    try {
      const { data, status } = await api.get(`/projects`);
      if (status == 200) {
        setProjectList(data);
      }
    } catch (error) {
      // alert(error);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const EmployeeProfile = async (e) => {
    try {
      const { data, status } = await api.get(`/employees`);
      if (status == 200) {
        setEmployee(data);
      }
    } catch (error) {
      // alert(error);
    }
  };
  useEffect(() => {
    EmployeeProfile();
  }, []);

  const handleAddProject = async (e) => {
    //e.preventDefault();
    setIsSubmitted(true);

    try {
      const { data } = await api.post("/projects", e);
      // setProjectList(data);
      console.log(e);
      fetchData();
      closeDialog();
      reset();
    } catch (error) {
      console.log(error);
    }
    // }
  };

  const AddTeamMember = () => {
    if (!inputData) {
    } else {
      const allInputData = {
        id: new Date().getTime().toString(),
        name: memberData,
        role: inputData,
      };
      setTeamMember([...teamMember, allInputData]);
      setInputData("");
      setMemberData("");
    }
  };
  const deleteTeamMember = (index) => {
    const updateTeamMember = teamMember.filter((elem) => {
      return index != elem.id;
    });
    setTeamMember(updateTeamMember);
  };

  useEffect(() => {
    localStorage.setItem("role", JSON.stringify(teamMember));
  }, [teamMember]);

  return (
    <>
      <Layout title={"Project"}>
        <div className="flex-lg-1 main-container  overflow-y-lg-auto">
          <main className="main-data">
            <Paper
              style={{ padding: 24, minHeight: 600, margin: 100 }}
              elevation={4}
            >
              <div className=" float-end me-5 mb-3">
                <Button
                  className="text-white"
                  variant="warning"
                  onClick={handleDialog}
                >
                  {" "}
                  <AddIcon /> Add Project
                </Button>
              </div>
              <div>
                <div className="col-lg-12 row">
                  <div className="col-lg-3">
                    <div
                      className="box-leave1 "
                      style={{ backgroundColor: "rgb(241, 231, 249)" }}
                    >
                      <h5 className="text- center">Total Project</h5>
                      <p className="text- center">12</p>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div
                      className="box-leave"
                      style={{ backgroundColor: "rgb(238, 225, 231)" }}
                    >
                      <h5 className="text- center">Active Project</h5>
                      <p className="text- center">3</p>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div
                      className="box-leave"
                      style={{ backgroundColor: "rgb(206, 217, 208)" }}
                    >
                      <h5 className="text- center">Cancel Project</h5>
                      <p className="text- center">4</p>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div
                      className="box-leave"
                      style={{ backgroundColor: "#FAA0A0" }}
                    >
                      <h5 className="text- center">Upcoming Project</h5>
                      <p className="text- center">5</p>
                    </div>
                  </div>
                </div>

                <div className="container-fluid">
                  <table
                    id="example"
                    className="table table-striped table-bordered"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th>Project Name</th>
                        {/* <th>Category</th> */}
                        <th>Customers</th>
                        {/* <th>Project Phase</th> */}
                        <th>Status</th>
                        {/* <th>Priority</th>
                        <th>Project Timeline</th>
                        <th>Expected End</th>
                        <th>Add Team Member</th>
                        <th>Members</th> */}
                        <th>Action</th>
                        <th>Standup</th>
                      </tr>
                    </thead>
                    {projectList?.map((item) => {
                      return (
                        <tbody>
                          <tr>
                            <td className="text-start">{item.projectName}</td>
                            {/* <td>{item.category}</td> */}
                            <td>{item.customerName}</td>
                            {/* <td>{item.projectPhase}</td> */}
                            <td>
                              <h4>
                                <Badge bg="success">{item.status}</Badge>
                              </h4>
                            </td>

                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="white"
                                  id="dropdown-basic"
                                >
                                  <i className="fa-solid fa-ellipsis-vertical"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item href="#">
                                    {" "}
                                    <EditIcon /> Edit
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#">
                                    <DeleteIcon /> Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            <td>
                              <Link to="/notes">
                                <button className="btn btn-dark">
                                  Standup
                                </button>
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </div>
            </Paper>
            <Dialog
              fullWidth
              open={showDialog}
              onClose={closeDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                style: {
                  backgroundColor: "white",
                  boxShadow: "none",
                  height: "800px",
                  width: "1000px",
                },
              }}
            >
              <DialogTitle
                className="text-start text-black"
                id="alert-dialog-title"
                style={{ fontSize: "28px", fontWeight: "600" }}
              >
                {"General Information"}
                <div className="float-end">
                  <CloseIcon
                    onClick={closeDialog}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </DialogTitle>
              <form onSubmit={handleSubmit(handleAddProject)}>
                <DialogContent>
                  <div className="col-lg-12">
                    <div className="form-field ticket-field mb-2">
                      <TextField
                        {...register("projectName")}
                        label="Project Name"
                        sx={{
                          width: 530,
                          borderColor: errors.projectName && "red",
                        }}
                      />

                      {errors.projectName && (
                        <span className="text-danger mx-2">
                          {errors.projectName.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="input-group mb-2">
                      <Autocomplete
                        // PaperProps={{ borderColor: error && "red" }}
                        // {...register("category")}
                        disablePortal
                        id="combo-box-demo"
                        options={category}
                        sx={{
                          width: 530,
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Category" />
                        )}
                        onChange={(e, nv) => {
                          setValue("category", nv);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-field ticket-field mb-2">
                      <Autocomplete
                        // PaperProps={{ borderColor: error && "red" }}
                        // {...register("customerName")}
                        disablePortal
                        id="combo-box-demo"
                        options={customerName}
                        sx={{
                          width: 530,
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Customer Name" />
                        )}
                        onChange={(e, nv) => {
                          setValue("customerName", nv);
                        }}
                      />
                    </div>
                    <div className="col-lg-12 row">
                      <div className="form-field ticket-field mb-2 col-lg-6">
                        {/* {showDateRangePicker ? (
                          <DateRange
                            editableDateInputs={true}
                            onChange={(item) => setState([item.selection])}
                            moveRangeOnFirstSelection={false}
                            ranges={state}
                          />
                        ) : (
                          <TextField
                            {...register("projectName")}
                            label="Project TimeLine"
                            sx={{
                              width: 530,
                              borderColor: errors.projectName && "red",
                            }}
                            onClick={() => {
                              setShowDateRangePicker(true);
                            }}
                            // onClick={setShowDateRangePicker(true)}
                          />
                        )} */}
                        <TextField
                          {...register("startdate")}
                          label="Start Date"
                          sx={{
                            width: 250,
                            borderColor: errors.projectName && "red",
                          }}
                        />

                        {/* <input
                          style={{
                            borderColor: errors.projectTimeline && "red",
                          }}
                          type="text"
                          {...register("projectTimeline")}
                          className="form-control"
                        /> */}
                      </div>
                      <div className="col-lg-6">
                        <TextField
                          {...register("enddate")}
                          label="End Date"
                          sx={{
                            width: 260,
                            borderColor: errors.projectName && "red",
                          }}

                          // onClick={setShowDateRangePicker(true)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-field ticket-field mb-2">
                        <Autocomplete
                          // PaperProps={{ borderColor: error && "red" }}
                          // {...register("projectPhase")}
                          disablePortal
                          id="combo-box-demo"
                          options={projectPhase}
                          sx={{
                            width: 530,
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Project Phase" />
                          )}
                          onChange={(e, nv) => {
                            setValue("projectPhase", nv);
                          }}
                        />

                        {/* <input
                          style={{ borderColor: errors.projectPhase && "red" }}
                          type="text"
                          {...register("projectPhase")}
                          className="form-control"
                        /> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 row">
                    <div className="col-lg-6">
                      <div className="form-field ticket-field mb-2">
                        {/* <input
                          style={{ borderColor: errors.status && "red" }}
                          type="text"
                          {...register("status")}
                          className="form-control"
                        /> */}
                        <Autocomplete
                          // PaperProps={{ borderColor: error && "red" }}
                          // {...register("status")}
                          disablePortal
                          id="combo-box-demo"
                          options={status}
                          sx={{
                            width: 250,
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Status" />
                          )}
                          onChange={(e, nv) => {
                            setValue("status", nv);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-field ticket-field mb-2">
                        <Autocomplete
                          // PaperProps={{ borderColor: error && "red" }}
                          // {...register("priority")}
                          disablePortal
                          id="combo-box-demo"
                          options={priority}
                          sx={{
                            width: 250,
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Priority" />
                          )}
                          onChange={(e, nv) => {
                            setValue("priority", nv);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <h3 className="my-2">Project Team</h3>
                  <div className="col-lg-12 row">
                    <div className="col-lg-6">
                      <div className="form-field ticket-field ">
                        <Autocomplete
                          // PaperProps={{ borderColor: error && "red" }}
                          // {...register("teamMember")}
                          disablePortal
                          id="combo-box-demo"
                          options={employee}
                          getOptionLabel={(option) => option.firstName}
                          sx={{
                            width: 250,
                          }}
                          onChange={(e, nv) => {
                            console.log(nv.firstName);
                            setMemberData(nv.firstName);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Member Name"
                              value={memberData}
                            />
                          )}
                        />
                        {/* <input
                          value={memberData}
                          onChange={(e) => setMemberData(e.target.value)}
                          type="text"
                          // {...register("addTeamMember")}
                          className="form-control"
                        /> */}
                        {/* {errors.addTeamMember && (
                          <span className="text-danger mx-2">
                            {errors.addTeamMember.message}
                          </span>
                        )} */}
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div className="form-field ticket-field ">
                        <Autocomplete
                          // PaperProps={{ borderColor: error && "red" }}
                          // {...register("member")}
                          disablePortal
                          id="combo-box-demo"
                          options={role}
                          sx={{
                            width: 200,
                          }}
                          onChange={(e, nv) => {
                            console.log(nv);
                            setInputData(nv);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Role"
                              value={inputData}
                            />
                          )}
                        />
                        {/* <input
                          value={inputData}
                          onChange={(e) => setInputData(e.target.value)}
                          // style={{
                          //   borderColor: errors.member && "red",
                          // }}
                          type="text"
                          // {...register("member")}
                          className="form-control"
                        />{" "} */}
                        {/* {errors.member && (
                          <span className="text-danger mx-2">
                            {errors.member.message}
                          </span>
                        )} */}
                      </div>
                    </div>
                    <div className="col-lg-1 mt-3">
                      <AddCircleOutlineRoundedIcon
                        className="mb-3"
                        style={{ fontSize: "35px", cursor: "pointer" }}
                        onClick={AddTeamMember}
                      />
                    </div>
                  </div>
                  {teamMember.map((item) => {
                    return (
                      <div className=" col-lg-12 row" key={item.id}>
                        <div className="col-lg-6">
                          <TextField
                            {...register("teammember")}
                            sx={{
                              width: 230,
                              borderColor: errors.projectName && "red",
                            }}
                            value={item.name}
                          />
                        </div>
                        <div className="col-lg-5">
                          <TextField
                            {...register("role")}
                            sx={{
                              width: 220,
                              borderColor: errors.projectName && "red",
                            }}
                            value={item.role}
                          />
                        </div>
                        <div className="col-lg-1 mt-3">
                          <DeleteIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteTeamMember(item.id)}
                          />
                        </div>
                      </div>
                    );
                  })}
                </DialogContent>
                <DialogActions>
                  {/* <button onClick={closeDialog} className="btn btn-secondary">
                Cancel
              </button> */}
                  {/* <div className="start-btn1 mx-2">
                    <button
                      autoFocus
                      className="btn btn-secondary"
                      onClick={closeDialog}
                    >
                      Cancel
                    </button>
                  </div> */}

                  {/* <button
                onClick={handleAddWorksheet}
                autoFocus
                className="btn btn-primary"
              >
                Add
              </button> */}
                  <div className="start-btn2 mx-2" style={{}}>
                    <button
                      type="submit"
                      autoFocus
                      className="btn btn-dark text-white"
                    >
                      Save
                    </button>
                  </div>
                </DialogActions>
              </form>
            </Dialog>
          </main>
        </div>
      </Layout>
    </>
  );
}

export default Project;
