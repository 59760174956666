import axios from "axios";

const api = axios.create({
  baseURL: "https://api.twelvesprings.uk/v1/",
  // baseURL: "http://localhost:9054/v1/",
  // validateStatus:  () => true
});

api.interceptors.request.use(
  function (config) {
    console.log("fds", window.location.pathname);
    if (
      !localStorage.getItem("authToken") &&
      localStorage.getItem("authToken") === null &&
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/signup"
    ) {
      window.location.href = window.location.origin;
    } else if (
      window.location.pathname === "/login" ||
      window.location.pathname === "/signup"
    ) {
    } else {
      config.headers["Authorization"] =
        "Bearer " + localStorage.getItem("authToken");
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const toFormData = (object) => {
  const formData = new FormData();
  Object.keys(object).forEach((key) => {
    formData.append(key, object[key]);
  });
  return formData;
};

export default api;
