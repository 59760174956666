import React, { useState } from "react";
import logo from "../../assets/images/logo1.png";
import "./signup.css";
import TimezoneSelect from "react-timezone-select";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { object, string, ref } from "yup";
import api from "../../lib/api";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";


import {
  FormProvider,
  useController,
  useForm,
  useFormContext,
} from "react-hook-form";

import {
  Checkbox,
  Tooltip,
  Chip,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { useToast } from "../../lib/toastctx";
import { useNavigate } from "react-router-dom";

const getCharacterValidationError = (str) => {
  return `Your password must have at least 1 ${str} character`;
};

const SignupSchema = yup.object().shape({
  firstName: yup.string().required("First Name required"),
  // firstname: yup.string().required("Firstname is required"),
  lastName: yup.string().required("Lastname is required"),
  middleName: yup
    .string()
    .required("Middlename is required")
    .min(4, "Please provide full middle name"),
  mobile: yup.string().required("Mobile is required")
  .min(10, "Invalid mobile number"),
  homePhone: yup.string().required("Home Phone / Emergency Phone is required"),
  authName: yup.string().required("login/username is required"),
  authPassword: yup
    .string()
    .required("Please enter a password")
    // check minimum characters
    .min(8, "Password must have at least 8 characters")
    .matches(/[0-9]/, getCharacterValidationError("digit"))
    .matches(/[a-z]/, getCharacterValidationError("lowercase"))
    .matches(/[A-Z]/, getCharacterValidationError("uppercase")),
  // different error messages for different requirements

  authConfirmPassword: yup
    .string()
    .required("Please re-type your password")
    // use oneOf to match one of the values inside the array.
    // use "ref" to get the value of passwrod.
    .oneOf([ref("authPassword")], "Passwords does not match"),
  adharcard: yup
    .string()
    .required("Identity Document - Aadhar Card Number is Mandatory")
    .min(12, "Min 12 character"),
  pancard: yup
    .string()
    .required("Identity Document - Pan Card Number is Mandatory")
    .min(10, "Min 10 character"),
  currentAddress: yup.string().required("Current Address is required"),
  permanentAddress: yup.string().required("Permanent Address is mandatory"),
  
  previouscompany1: yup.string().required("Previous Company Name is Mandatory"),
  verificationemail1: yup.string().required("Verification Email is Mandatory"),
  verificationname1: yup
    .string()
    .required("Verification Contact Name is Mandatory"),
  previousSalary1: yup.string().required("CTC/Salary is Mandatory"),
  
  highSchool: yup.string().required("High/Higher Secondary School Name is Mandatory"),
  degree: yup.string().required("Degree is Mandatory"),
});

function RHFTimezone({ control, name }) {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules: { required: true },
    defaultValue: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  return <TimezoneSelect value={value} onChange={onChange} onBlur={onBlur} />;
}

function FormSection({ title, children }) {
  return (
    <div>
      <h4 className="text-start pb-2" style={{ fontWeight: 500 }}>
        {title}
      </h4>
      {children}
    </div>
  );
}

function FormField({ name, label, inputProps }) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = errors[name];

  return (
    <div class="form-floating mb-3">
      <input
        style={{ borderColor: error && "red" }}
        {...inputProps}
        {...register(name)}
        type="text"
        className={`form-control`}
        id="floatingInput"
        placeholder={label}
      />
      
      <label className="control-label" for="floatingInput">{label}</label>
      {error && <span style={{ color: "red" }}>{error.message}</span>}
    </div>
  );
}

function FormFieldTextarea({ name, label, inputProps }) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = errors[name];

  return (
    <div class="form-floating mb-3">
      <input
        style={{ borderColor: error && "red", height: 100 }}
        {...inputProps}
        {...register(name)}
        type="text"
        className={`form-control`}
        id="floatingInput"
        placeholder={label}
      />
      <label for="floatingInput">{label}</label>
      {error && <span style={{ color: "red" }}>{error.message}</span>}
    </div>
  );
}

function FormFieldPassword({ name, label, inputProps, watch }) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = errors[name];

  return (
    <div class="form-floating mb-3">
      <input
        style={{ borderColor: error && "red" }}
        {...inputProps}
        {...register(
          name, {
          validate: (authConfirmPassword) => {
            alert(authConfirmPassword)
            if (watch("authPassword") != authConfirmPassword) {
              alert('error');
            }
          }
        }
        )
        }
        type="password"
        className={`form-control`}
        id="floatingInput"
        placeholder={label}
      />
      <label for="floatingInput">{label}</label>
      {error && <span style={{ color: "red" }}>{error.message}</span>}
    </div>
  );
}


// hh:mm a
export default function SignUp() {
  const [sd, onChange] = useState(new Date());

  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [termscheck, setTermsCheck] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(false);

  const navigate = useNavigate();

  const formProps = useForm({
    resolver: yupResolver(SignupSchema),
    defaultValues: {
      startdate: dayjs(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = formProps;

  const startdateField = register("startdate");
  const starttimeField = register("starttime");
  const endtimeField = register("endtime");
  const timezoneField = register("timezone");

  const { openToast } = useToast();

  const onSubmit = (data) => {
    const { timezone } = data;
    if (typeof timezone === 'object') {
      data.timezone = timezone.value
    }
    api.post("signup", data, {
      headers: { "Content-Type": "application/json" },
    })
      .then(({ data, status }) => {
        openToast('success', "Your signup was successful");
        navigate("/login")
      }).catch(({ response: { data } }) => {
        if (data)
          openToast('error', data.message || "Error");
      });
  };

  // const onSubmit = async (e) => {
  // 	//e.preventDefault();
  // 	// let currentDate = new Date().toISOString();
  // 	// let date = currentDate.substring(0, 10);
  // 	// let time = currentDate.substring(11, 19);
  // 	try {
  // 		console.log(formProps);
  // 	  const { data } = await api.post("/signup", {

  // 	  }
  // 		);
  // 	  console.log(data);
  // 	} catch (error) {
  // 	  console.log(error);
  // 	}
  // 	// }
  //   };

  const onChangeTimeZone = (event) => {
    setTimezone(event.value);
  };

  const handleTermsCheck = (event) => {
    setTermsCheck(event.target.checked);
    if (event.target.checked) {
      setButtonStatus(true);
    } else {
      setButtonStatus(false);
    }
  };

  return (
    <body style={{ backgroundColor: "#FAFAFA" }}>
      <section class="singup">
        <div class="container g-0 text-start" style={{ maxWidth: "50%" }}>
          <div class="row g-0">
            <div class="col-lg-12">
              <div class="logo-img">
                <img src={logo} />
              </div>
            </div>
          </div>

          <div class="row g-0" style={{ width: "100%", display: "flex", flexDirection: "column", justifySelf: "center" }} >
            <div class="col-lg-12" >
              <div
                class="headingSection"
                style={{ marginTop: 70, marginBottom: 25 }}
              >
                <h4>Consultant Registration Form</h4>
                {/* <p>Please select your appropriate role</p> */}
              </div>
            </div>

            {/* React Hook Form */}

            <FormProvider {...formProps}>
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                {/* general information div */}
                <div
                  className="shadow p-5 mb-7 rounded-4"
                  style={{ backgroundColor: "white" }}
                >

                  <div>
                    <FormSection title="Personal Information">
                      <FormField name="firstName" label="First Name" />
                      <FormField name="middleName" label="Middle Name" />
                      <FormField name="lastName" label="Last Name" />
                      <FormField name="mobile" label="Mobile" />
                      <FormField name="homePhone" label="Home Phone / Emergency Phone" />
                    </FormSection>
                  </div>

                  <div>
                    <FormSection title="Login Information">
                      <FormField name="authName" label="Please enter your login name" />
                      <FormFieldPassword name="authPassword" label="Password" />
                      <FormFieldPassword name="authConfirmPassword" label="Confirm Password" />
                    </FormSection>
                  </div>

                  <div>
                    <FormSection title="Identity Information">
                      <Chip
                        label="We will do verification with original copy"
                        color="warning"
                      />
                      <p></p>
                      <FormField name="adharcard" label="Aadhar Card Number" />
                      <FormField name="pancard" label="Pan Card Number" />
                    </FormSection>

                  </div>

                  <div>
                    <FormSection title="Job Information">
                      {/* <Chip
                        label="Do no include any bonus amount in salary. it should be your net salary discussed"
                        color="warning"
                      />
                      <p></p>
                      <FormField name="salary" label="Net Salary (Without any bonus)" /> */}
                      <FormField name="joindate" label="Date you started with us [ dd-mm-yyyy format] " />
                    </FormSection>

                  </div>

                  <div>
                    <FormSection title="Address Information">

                      <Chip
                        label="Please provide your postal address."
                        color="warning"
                      />
                      <p></p>
                      <FormFieldTextarea name="currentAddress" label="Current Address" />
                      <FormFieldTextarea name="permanentAddress" label="Permanent Address" />
                    </FormSection>

                  </div>

                  <div>
                    <FormSection title="Employer 1 (Previous Employer)">
                      <Chip
                        label="Please make sure this information is correct. we will do background checks"
                        color="warning"/>
                      <p></p>
                      <FormField name="previouscompany1" label="Tell us the name of your previous company" />
                      <FormField name="verificationemail1" label="Which email we should send verification" />
                      <FormField name="verificationname1" label="Please tell us name of the person to whom you were reporting."/>
                      <FormField name="previousSalary1" label="Please tell us your salary with previous company" />
                    </FormSection>
                    <FormSection title="Employer 2 (Previous Employer)" >
                      <FormField name="previouscompany2" label="Tell us the name of your previous company" />
                      <FormField name="verificationemail2" label="Which email we should send verification" />
                      <FormField name="verificationname2" label="Please tell us name of the person to whom you were reporting."/>
                      <FormField name="previousSalary2" label="Please tell us your salary with previous company" />
                    </FormSection>
                  </div>
                  <div>
                  <FormSection title="Qualification Information" >
                      <FormFieldTextarea name="highSchool" label="Please provide name and grades of your Higher Secondary" />
                      <FormFieldTextarea name="degree" label="Please provide name and grades of your bachelor's degree " />
                      <FormFieldTextarea name="masters" label="Please provide name and grades of your master degree" />
                    </FormSection>
                  </div>




                  <div style={{ margintop: "45px" }}>
                    <div>
                      <h4
                        className="text-start pb-2 mt-5"
                        style={{ fontWeight: 500 }}
                      >
                        Timezone Information
                      </h4>
                      <div class="form-floating mb-3">
                        <RHFTimezone control={control} name="timezone" />
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div class="col-lg-12">
							<div class="form-floating">
								<select class="form-select" id="floatingSelect" aria-label="Floating label select example">
									<option selected>Select timezone</option>
									{Intl.supportedValuesOf('timeZone').map((tz) => (
										<option>{tz}</option>
									))}
								</select>
								<label for="floatingSelect">Works with selects</label>
							</div>
						</div> */}

                <div style={{ marginTop: 25 }}>
                  <FormControlLabel
                    required
                    control={<Checkbox onChange={handleTermsCheck} />}
                    label="I confirm that above provided information are correct"
                  />
                </div>
                <div class="col-lg-12" style={{ marginTop: 25 }}>
                  <div>
                    {buttonStatus ? (
                      <button
                        type="submit"
                        class="btn btn-dark btn-lg mb-5"
                        style={{
                          height: 75,
                          width: 200,
                          fontSize: 26,
                          fontWeight: 600,
                        }}
                      >
                        Save
                        {/* <img src={nextStep} /> */}
                      </button>
                    ) : (
                      <button
                        disabled
                        type="submit"
                        class="btn btn-dark btn-lg mb-5"
                        style={{
                          height: 75,
                          width: 200,
                          fontSize: 26,
                          fontWeight: 600,
                        }}
                      >
                        Save
                        {/* <img src={nextStep} /> */}
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </section>
    </body>
  );
}
